import React, { PropsWithChildren } from "react";
import { Trans, WithTranslation } from "react-i18next";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import {
  Form,
  FormikProps,
  FormikProvider,
  FormikValues,
  useFormik,
  useFormikContext
} from "formik";
import { ShoppingCartIcon } from "sharedComponents/icons/DesignSystemIcons/ShoppingCartIcon";
import SelectedProductCard from "client/components/SelectedProductCard/SelectedProductCard";
import { CompanyFoundingIcon } from "sharedComponents/icons/DesignSystemIcons/CompanyFoundingIcon";
import { AdminServiceIcon } from "sharedComponents/icons/DesignSystemIcons/AdminServiceIcon";
import { LowTaxBrokerIcon } from "sharedComponents/icons/DesignSystemIcons/LowTaxBrokerIcon";
import { formatCurrency } from "common/formatters";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { RideCheckbox } from "sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import { LinkVariant, TextLink } from "sharedComponents/TextLink/TextLink";
import { RideUrls } from "common/URLs";
import { DiscountCodeSection } from "client/components/ChaptersOrderLayout/sharedComponents/DiscountCodeSection/DiscountCodeSection";
import * as Yup from "yup";
import {
  Product,
  UnifiedShortOrderFormServerData
} from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormServerData";
import { toUnifiedShortOrderFormData } from "lib/dataAdapters/toUnifiedShortOrderFormData/toUnifiedShortOrderFormData";
import { OrderSummary } from "lib/models/client/UnifiedShortOrderForm/OrderSummary";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { DiscountCodeAllowedServicesEnum, FeatureFlags } from "global-query-types";
import { RideBadge, RideBadgeVariant } from "uiLibrary/components/RideBadge/RideBadge";
import { UtmParams } from "lib/services/UtmService/UtmService";
import { useRideField } from "lib/hooks/useRideField";
import {
  StepContent,
  StepContinueButton,
  StepHeader,
  StepLayout
} from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/StepLayout/StepLayout";
import { OrderCostSummary } from "client/components/UnifiedShortOrderForm/CheckoutStep/OrderCostSummary/OrderCostSummary";
import { UnifiedShortOrderFormStepProps } from "client/components/UnifiedShortOrderForm/UnifiedShortOrderForm.partials";
import { CalendarIcon } from "sharedComponents/icons/DesignSystemIcons/CalendarIcon";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";

const shortOrderFormCheckoutStepModule = "short-order-form-checkout-step";

export interface CheckoutStepProps extends UnifiedShortOrderFormStepProps {
  verifyDiscountCode: (
    discountCode: string,
    selectedProducts: DiscountCodeAllowedServicesEnum[]
  ) => Promise<{ isValid: boolean; discountValue: number }>;
  onSubmit: (values: UnifiedShortOrderFormServerData) => Promise<void>;
  utmParams?: UtmParams;
}

const CheckoutStep = ({
  t,
  initialValues,
  verifyDiscountCode,
  goToPreviousStep,
  goToNextStep,
  persistData,
  utmParams,
  onSubmit
}: CheckoutStepProps & WithTranslation) => {
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      discountCode: undefined,
      discountValue: 0,
      companyFoundingService: initialValues.companyFoundingService,
      adminService: initialValues.adminService,
      lowTaxBroker: initialValues.lowTaxBroker,
      hasCorporateShareholders: initialValues.hasCorporateShareholders,
      termsAndConditionsCheckbox: initialValues.termsAndConditionsCheckbox ?? false,
      privacyPolicyCheckbox: initialValues.privacyPolicyCheckbox ?? false
    },
    onSubmit: async () => {
      await onSubmit(toUnifiedShortOrderFormData(formik.values, utmParams));
      goToNextStep?.();
    },
    validationSchema: ShortOrderFormCheckoutSchema(t)
  });

  const persistAndGoBack = () => {
    persistData(formik.values);
    goToPreviousStep?.();
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <CheckoutStepLayout persistAndGoBack={persistAndGoBack}>
          <YourOrder formik={formik} verifyDiscountCode={verifyDiscountCode} />
          <TermsAndConditions formik={formik} />
        </CheckoutStepLayout>
      </Form>
    </FormikProvider>
  );
};

interface CheckoutStepLayoutProps extends PropsWithChildren<{}> {
  persistAndGoBack: () => void;
}

const CheckoutStepLayout = withTranslationReady(["generic"])(
  ({ t, children, persistAndGoBack }: CheckoutStepLayoutProps & WithTranslation) => (
    <StepLayout className="checkout-step" data-testid="checkout-step">
      <StepContent>
        <StepHeader
          data-testid={"checkout-step-header"}
          title={t("generic:short-order-form.checkout.title")}
          subtitle={t("generic:short-order-form.checkout.subtitle")}
          backButtonHandler={persistAndGoBack}
        />
        <div className="short-order-form-checkout-step__content">{children}</div>
      </StepContent>
      <StepContinueButton data-testid={"submit-button"} label={t("generic:order-with-costs")} />
    </StepLayout>
  )
);

const YourOrder = ({
  formik,
  verifyDiscountCode
}: {
  formik: FormikProps<any>;
  verifyDiscountCode: (
    discountCode: string,
    selectedProducts: DiscountCodeAllowedServicesEnum[]
  ) => Promise<{ isValid: boolean; discountValue: number }>;
}) => {
  const orderSummary = OrderSummary.fromShortOrderForm(formik.values);
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  return (
    <>
      <YourOrderSelectedProducts orderSummary={orderSummary} />
      {!onlyBroker && (
        <>
          <DiscountCode verifyDiscountCode={verifyDiscountCode} />
          <OrderCostSummary orderSummary={orderSummary} />
        </>
      )}
    </>
  );
};

const YourOrderSelectedProducts = withTranslationReady(["generic"])(
  ({
    t,
    orderSummary
  }: {
    orderSummary: OrderSummary;
  } & WithTranslation) => {
    const adminServiceTierBadgeConfig = {
      [AdminServiceTier.Lite]: <RideBadge label="Lite" variant={RideBadgeVariant.OCRE} />,
      [AdminServiceTier.Standard]: <RideBadge label="Standard" variant={RideBadgeVariant.PURPLE} />,
      [AdminServiceTier.Pro]: <RideBadge label="Pro" variant={RideBadgeVariant.GREEN} />
    };

    const adminServiceTier = orderSummary.getItem(Product.AS)?.tier;

    const adminServiceBadge = adminServiceTier
      ? adminServiceTierBadgeConfig[adminServiceTier]
      : undefined;

    const adminServiceItem = orderSummary.getItem(Product.AS);
    return (
      <div className={`${shortOrderFormCheckoutStepModule}__your-order`}>
        <div className={`${shortOrderFormCheckoutStepModule}__your-order-header`}>
          <ShoppingCartIcon />
          <Typography
            data-testid="your-order-header"
            category="Paragraph"
            size={100}
            weight={"Heavy"}>
            {t("generic:your-order")}
          </Typography>
        </div>
        <div className={`${shortOrderFormCheckoutStepModule}__selected-products`}>
          {orderSummary.hasItem(Product.GS) && (
            <SelectedProductCard
              data-testid="gmbhGrundung"
              icon={<CompanyFoundingIcon />}
              title={t("generic:product-card.gmbh-grundung.title")}
              due={t("generic:paid-after-founding")}
              price={formatCurrency(orderSummary.getRecurringPrice(Product.GS), { fixed: 0 })}
            />
          )}
          {orderSummary.hasItem(Product.AS) && (
            <>
              <SelectedProductCard
                data-testid="adminService"
                icon={<AdminServiceIcon />}
                title={t("generic:product-card.admin-service.title")}
                due={t("generic:after-contract-signature")}
                price={formatCurrency(orderSummary.getRecurringPrice(Product.AS), { fixed: 0 })}
                badge={adminServiceBadge}
              />
              {!!adminServiceItem?.numberOfAdditionalYears && (
                <SelectedProductCard
                  data-testid="additionalYears"
                  icon={<CalendarIcon />}
                  title={t(
                    "generic:product-card.admin-service-additional-years.cost-summary.card-title",
                    { years: adminServiceItem.numberOfAdditionalYears }
                  )}
                  price={formatCurrency(
                    orderSummary.getRecurringPrice(Product.AS)! *
                      adminServiceItem.numberOfAdditionalYears,
                    { fixed: 0 }
                  )}
                />
              )}
            </>
          )}
          {orderSummary.hasItem(Product.LTB) && (
            <SelectedProductCard
              data-testid="lowTaxBroker"
              icon={<LowTaxBrokerIcon />}
              title={t(rideBrokerT("generic:product-card.low-tax-broker.title"))}
              price={t("generic:product-card.low-tax-broker.price")}
            />
          )}
        </div>
      </div>
    );
  }
);

const DiscountCode = ({
  verifyDiscountCode
}: {
  verifyDiscountCode: (
    discountCode: string,
    selectedProducts: DiscountCodeAllowedServicesEnum[]
  ) => Promise<{ isValid: boolean; discountValue: number }>;
}) => {
  const formik = useFormikContext<any>();

  const discountValue = useRideField("discountValue");

  const handleDiscount = (discount: number) => {
    discountValue.setValue(discount);
  };

  return (
    <div className={`${shortOrderFormCheckoutStepModule}__discount-code`}>
      <DiscountCodeSection
        dataTestId="ride-discount-code"
        formik={formik}
        verifyDiscountCode={(discountCode: string) => {
          return verifyDiscountCode(
            discountCode,
            parseSelectedProductsToAllowedDiscountCodeService(formik.values)
          );
        }}
        setDiscountValue={handleDiscount}
        useDesignSystem={true}
      />
    </div>
  );
};

const TermsAndConditions = ({ formik }: { formik: FormikProps<any> }) => {
  return (
    <div className={`${shortOrderFormCheckoutStepModule}__checkboxes`}>
      <RideCheckbox
        name="termsAndConditionsCheckbox"
        formik={formik}
        shouldShowErrorFeedback={true}
        label={
          <Typography category="Paragraph" size={100} weight={"Light"}>
            <Trans
              i18nKey="generic:short-order-form.checkboxes.terms-conditions"
              components={{
                termsAndConditionsLink: (
                  <TextLink
                    to={RideUrls.TermsAndConditions}
                    variant={LinkVariant.secondary}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                )
              }}
            />
          </Typography>
        }
      />
    </div>
  );
};

const ShortOrderFormCheckoutSchema = (t) => {
  return Yup.object().shape({
    termsAndConditionsCheckbox: Yup.boolean().isTrue(t("generic:validation-required"))
  });
};

function parseSelectedProductsToAllowedDiscountCodeService(values: FormikValues) {
  const selectedProducts: DiscountCodeAllowedServicesEnum[] = [];
  if (values["adminService"] !== undefined) {
    selectedProducts.push(DiscountCodeAllowedServicesEnum.AS);
  }
  if (values["companyFoundingService"] === true) {
    selectedProducts.push(DiscountCodeAllowedServicesEnum.GS);
  }
  return selectedProducts;
}

export default withTranslationReady(["generic"])(CheckoutStep);
