import React, { PropsWithChildren, useEffect } from "react";
import { TFunction, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProvider, useField } from "formik";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";
import AdminServiceProductCard from "client/components/UnifiedShortOrderForm/ProductSelectionStep/AdminServiceProductCard/AdminServiceProductCard";
import LTBProductCard from "client/components/UnifiedShortOrderForm/ProductSelectionStep/LTBProductCard/LTBProductCard";
import CompanyFoundingProductCard from "client/components/UnifiedShortOrderForm/ProductSelectionStep/CompanyFoundingProductCard/CompanyFoundingProductCard";
import * as Yup from "yup";
import {
  StepContent,
  StepContinueButton,
  StepHeader,
  StepLayout
} from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/StepLayout/StepLayout";
import { AdminServiceTier } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { UnifiedShortOrderFormStepProps } from "client/components/UnifiedShortOrderForm/UnifiedShortOrderForm.partials";
import AdminServiceAdditionalYears from "./AdminServiceAdditionalYears/AdminServiceAdditionalYears";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "../../../../global-query-types";
import { FeatureFlagService } from "../../../../lib/services/FeatureFlagService/FeatureFlagService";
import { getBlockedTiersForAdminService } from "../../../../common/getBlockedTiersForAdminService";

export interface ProductSelectionStepProps extends UnifiedShortOrderFormStepProps {}

const ProductSelectionStep = ({
  t,
  persistData,
  initialValues,
  goToNextStep,
  goToPreviousStep
}: ProductSelectionStepProps & WithTranslation) => {
  const formik = useRideFormik({
    initialValues: {
      ...initialValues,
      companyFoundingService: initialValues.customerAlreadyHasCompany === "no"
    },
    validationSchema: ProductSelectionStepSchema(t),
    onSubmit: async (values) => {
      persistData(values);
      goToNextStep?.();
    }
  });

  const persistAndGoBack = () => {
    persistData(formik.values);
    goToPreviousStep?.();
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <ProductSelection goBackHandler={persistAndGoBack} />
      </Form>
    </FormikProvider>
  );
};

interface ProductSelectionProps extends WithTranslation {
  goBackHandler: () => void;
}

const ProductSelection = withTranslationReady(["generic"])(
  ({ t, goBackHandler }: ProductSelectionProps) => {
    const [customerAlreadyHasCompanyField] = useField("customerAlreadyHasCompany");
    const [companyLegalFormField] = useField("companyLegalForm");
    const [lowTaxBrokerField] = useField("lowTaxBroker");
    const [companyOwnsRealEstateField] = useField("companyOwnsRealEstate");
    const [companyDoesNotFallIntoCategoriesField] = useField("companyDoesNotFallIntoCategories");
    const [tradesCryptoField] = useField("tradesCrypto");
    const [hasFiveOrMoreEmployeesField] = useField("hasFiveOrMoreEmployees");
    const [companyIssuesMoreThan50OutgoingInvoicesYearlyField] = useField(
      "companyIssuesMoreThan50OutgoingInvoicesYearly"
    );
    const [companyKeepsPhysicalInventoryField] = useField("companyKeepsPhysicalInventory");
    const [adminServiceField, , { setValue: setAdminServiceValue }] = useField("adminService");

    const isAllowedToOrderLTB =
      customerAlreadyHasCompanyField.value === "yes" && companyLegalFormField.value !== undefined
        ? ["GmbH", "UG"].includes(companyLegalFormField.value)
        : true;

    const adminServiceQuestions = FeatureFlagService.instance.isEnabled(
      FeatureFlags.CompanyCategoryQuestionShortOrderForm
    );

    const disabledAdminServiceTiers = getBlockedTiersForAdminService(
      {
        orderingBroker: lowTaxBrokerField.value,
        companyOwnsRealEstate: companyOwnsRealEstateField.value,
        companyDoesNotFallIntoCategories: companyDoesNotFallIntoCategoriesField.value,
        hasFiveOrMoreEmployees: hasFiveOrMoreEmployeesField.value,
        companyIssuesMoreThan50OutgoingInvoicesYearly:
          companyIssuesMoreThan50OutgoingInvoicesYearlyField.value,
        companyKeepsPhysicalInventory: companyKeepsPhysicalInventoryField.value,
        tradesCrypto: tradesCryptoField.value
      },
      adminServiceQuestions
    );

    useEffect(() => {
      if (lowTaxBrokerField.value && adminServiceField.value === AdminServiceTier.Lite) {
        setAdminServiceValue(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lowTaxBrokerField.value, adminServiceField.value]);

    const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

    return (
      <ProductSelectionLayout t={t} goBackHandler={goBackHandler}>
        <ErrorMessageWithT
          className="product-selection-step__error"
          name="adminService"
          extra={{}}
          ignoreTouch
        />
        <div className="product-selection-step__options">
          {customerAlreadyHasCompanyField.value === "no" && (
            <CompanyFoundingProductCard inputName={"companyFoundingService"} disabled={true} />
          )}
          <LTBProductCard inputName={"lowTaxBroker"} disabled={!isAllowedToOrderLTB} />
          {!onlyBroker && (
            <>
              <AdminServiceProductCard
                inputName={"adminService"}
                disabledOptions={disabledAdminServiceTiers}
              />
              {adminServiceField.value && (
                <FeatureFlag name={FeatureFlags.ShowAdditionalFiscalYearSectionInUnifiedOrderForm}>
                  <AdminServiceAdditionalYears
                    inputName={"startingFiscalYear"}
                    tier={adminServiceField.value}
                  />
                </FeatureFlag>
              )}
            </>
          )}
        </div>
      </ProductSelectionLayout>
    );
  }
);

const ProductSelectionLayout = ({
  t,
  children,
  goBackHandler
}: { t: TFunction; goBackHandler: () => void } & PropsWithChildren<{}>) => {
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  if (onlyBroker) {
    return (
      <StepLayout data-testid="product-selection-step">
        <StepContent>
          <StepHeader
            data-testid={"product-selection-step-header"}
            title={t("generic:ride-broker-only-signup-order-form.product-selection.title")}
            subtitle={t("generic:ride-broker-only-signup-order-form.product-selection.subtitle")}
            backButtonHandler={goBackHandler}
          />
          {children}
        </StepContent>
        <StepContinueButton label={t("generic:continue")} />
      </StepLayout>
    );
  }

  return (
    <StepLayout data-testid="product-selection-step">
      <StepContent>
        <StepHeader
          data-testid={"product-selection-step-header"}
          title={t("generic:short-order-form.product-selection.title")}
          subtitle={t("generic:short-order-form.product-selection.subtitle")}
          backButtonHandler={goBackHandler}
        />
        {children}
      </StepContent>
      <StepContinueButton label={t("generic:continue")} />
    </StepLayout>
  );
};

const ProductSelectionStepSchema = (t) => {
  return Yup.object().shape({
    companyFoundingService: Yup.boolean(),
    lowTaxBroker: Yup.boolean(),
    adminService: Yup.string().when(["companyFoundingService", "lowTaxBroker"], {
      is: (companyFoundingService, lowTaxBroker) => !companyFoundingService && !lowTaxBroker,
      then: Yup.string()
        .oneOf(Object.values(AdminServiceTier), t("generic:validation-select-at-least-one"))
        .required(t("generic:validation-select-at-least-one"))
    })
  });
};

export default withTranslationReady(["generic"])(ProductSelectionStep);
