import React, { PropsWithChildren, useEffect, useState } from "react";
import { TFunction, Trans, WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { Form, FormikProps, FormikProvider } from "formik";
import { UnifiedShortOrderFormValues } from "lib/models/client/UnifiedShortOrderForm/UnifiedShortOrderFormValues";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { FeatureFlags, LegalForm } from "global-query-types";
import {
  StepContent,
  StepContinueButton,
  StepHeader,
  StepLayout
} from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/StepLayout/StepLayout";
import { useRideField } from "lib/hooks/useRideField";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import {
  ExternalCompanyDataByName_externalCompanyDataByName_companies,
  ExternalCompanyDataByName_externalCompanyDataByName_companies_companyData
} from "lib/api/queries/graphql/ExternalCompanyDataByName";
import SelectedExternalCompanyCard from "client/components/SelectedExternalCompanyCard/SelectedExternalCompanyCard";
import { ExternalCompanySearchInputContainer } from "client/components/ExternalCompanySearchInput/ExternalCompanySearchInputContainer";
import { BusinessIcon } from "sharedComponents/icons/DesignSystemIcons/BusinessIcon";
import { UnifiedShortOrderFormStepProps } from "client/components/UnifiedShortOrderForm/UnifiedShortOrderForm.partials";
import * as Yup from "yup";
import { removeTypenames, WithoutTypenames } from "../../../../testTools/helpers/typename";
import { CreateCompanyLeadForUnifiedShortOrderFormVariables } from "../../../../lib/api/mutations/graphql/CreateCompanyLeadForUnifiedShortOrderForm";
import { ErrorMessageWithT } from "components/generic/ErrorMessage";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import CompanyCategoriesQuestion from "./Questions/CompanyCategoriesQuestion/CompanyCategoriesQuestion";
import RealStateQuestion from "./Questions/RealStateQuestion/RealStateQuestion";
import CorporateShareholdersQuestion from "./Questions/CorporateShareholdersQuestion/CorporateShareholdersQuestion";
import CryptoTradingQuestion from "./Questions/CryptoTradingQuestion/CryptoTradingQuestion";
import SelectYourCompanyLegalFormQuestion from "./Questions/SelectYourCompanyLegalFormQuestion/SelectYourCompanyLegalFormQuestion";
import DoYouHaveACompanyQuestion from "./Questions/DoYouHaveACompanyQuestion/DoYouHaveACompanyQuestion";
import { RideCheckbox } from "../../../../sharedComponents/FormControls/RideCheckbox/RideCheckbox";
import CompanyActivelyOperatingQuestion from "./Questions/CompanyActivelyOperatingQuestion/CompanyActivelyOperatingQuestion";

export interface CompanyInformationStepProps extends UnifiedShortOrderFormStepProps {
  createCompanyLeadHandler: ({
    companyData,
    companyAddress,
    hasCorporateShareholders,
    contactEmail
  }: CreateCompanyLeadForUnifiedShortOrderFormVariables) => Promise<void>;
}

const CompanyInformationStep = ({
  t,
  initialValues,
  persistData,
  goToNextStep,
  goToPreviousStep,
  createCompanyLeadHandler
}: CompanyInformationStepProps & WithTranslation) => {
  const companyCategoryQuestionIsEnabled = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CompanyCategoryQuestionShortOrderForm
  );

  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  const formik = useRideFormik({
    initialValues: {
      ...initialValues,
      customerAlreadyHasCompany: onlyBroker
        ? "yes"
        : initialValues.customerAlreadyHasCompany ?? undefined,
      companyName: initialValues.companyName ?? undefined,
      companyLegalForm: initialValues.companyLegalForm ?? undefined,
      hasCorporateShareholders: initialValues.hasCorporateShareholders ?? undefined,
      companyDoesNotFallIntoCategories: initialValues.companyDoesNotFallIntoCategories ?? undefined,
      companyOwnsRealEstate: initialValues.companyOwnsRealEstate ?? undefined,
      tradesCrypto: initialValues.tradesCrypto ?? undefined,
      truthfulInformationProvided: initialValues.truthfulInformationProvided ?? undefined,
      companyActivelyOperating: initialValues.companyActivelyOperating ?? undefined,
      companyKeepsPhysicalInventory: initialValues.companyKeepsPhysicalInventory ?? undefined,
      companyIssuesMoreThan50OutgoingInvoicesYearly:
        initialValues.companyIssuesMoreThan50OutgoingInvoicesYearly ?? undefined,
      hasFiveOrMoreEmployees: initialValues.hasFiveOrMoreEmployees ?? undefined
    },
    validationSchema: CompanyInformationStepSchema(t, companyCategoryQuestionIsEnabled),
    onSubmit: async () => {
      persistData(formik.values);

      if (formik.values.customerAlreadyHasCompany === "yes") {
        await createCompanyLeadHandler({
          companyData: {
            companyName: formik.values.companyName ?? "",
            legalForm: (formik.values.companyLegalForm as LegalForm) ?? undefined,
            registerCourt: formik.values.registerCourt ?? undefined,
            registration: formik.values.registration ?? undefined
          },
          companyAddress: formik.values.companyAddress,
          hasCorporateShareholders: formik.values.hasCorporateShareholders === "yes",
          contactEmail: formik.values.email ?? ""
        });
      }

      goToNextStep();
    }
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <CompanyInformationLayout
          t={t}
          goToPreviousStep={goToPreviousStep}
          persistData={persistData}
          formik={formik}>
          <CompanyInformationContent t={t} formik={formik} />
        </CompanyInformationLayout>
      </Form>
    </FormikProvider>
  );
};

const CompanyInformationContent = ({ t, formik }: { t: TFunction; formik: FormikProps<any> }) => {
  const customerAlreadyHasCompanyField = useRideField("customerAlreadyHasCompany");
  const companyFoundingServiceField = useRideField("companyFoundingService");
  const legalFormField = useRideField("companyLegalForm");
  const registerCourtField = useRideField("registerCourt");
  const registrationField = useRideField("registration");
  const lowTaxBrokerField = useRideField("lowTaxBroker");
  const hasCorporateShareholdersField = useRideField("hasCorporateShareholders");
  const northDataUniqueKeyField = useRideField("northDataUniqueKey");
  const companyNameField = useRideField("companyName");
  const companyAddress = useRideField("companyAddress");
  const companyDoesNotFallIntoCategoriesField = useRideField("companyDoesNotFallIntoCategories");
  const companyOwnsRealEstateField = useRideField("companyOwnsRealEstate");
  const adminServiceField = useRideField("adminService");
  const tradesCryptoField = useRideField("tradesCrypto");
  const companyActivelyOperatingField = useRideField("companyActivelyOperating");
  const companyKeepsPhysicalInventoryField = useRideField("companyKeepsPhysicalInventory");
  const companyIssuesMoreThan50OutgoingInvoicesYearlyField = useRideField(
    "companyIssuesMoreThan50OutgoingInvoicesYearly"
  );
  const hasFiveOrMoreEmployeesField = useRideField("hasFiveOrMoreEmployees");
  const truthfulInformationProvidedField = useRideField("truthfulInformationProvided");

  const hasSelectedCompany = !!registrationField.value;
  const useCompanySearchInput = FeatureFlagService.instance.isEnabled(
    FeatureFlags.CompanySearchInputShortOrderForm
  );

  const [fillCompanyInformationManually, setFillCompanyInformationManually] = useState<boolean>(
    (!!legalFormField.value && !hasSelectedCompany) || !useCompanySearchInput
  );

  function clearAddExistingCompanyFields() {
    companyFoundingServiceField.setValue(undefined);
    registerCourtField.setValue(undefined);
    registrationField.setValue(undefined);
    legalFormField.setValue(undefined);
    companyNameField.setValue(undefined);
    companyAddress.setValue(undefined);
    hasCorporateShareholdersField.setValue(undefined);
    companyDoesNotFallIntoCategoriesField.setValue(undefined);
    companyOwnsRealEstateField.setValue(undefined);
    tradesCryptoField.setValue(undefined);
    adminServiceField.setValue(undefined);
    companyActivelyOperatingField.setValue(undefined);
    companyIssuesMoreThan50OutgoingInvoicesYearlyField.setValue(undefined);
    hasFiveOrMoreEmployeesField.setValue(undefined);
    companyKeepsPhysicalInventoryField.setValue(undefined);
    truthfulInformationProvidedField.setValue(undefined);
  }

  useEffect(() => {
    if (companyActivelyOperatingField.value === "no") {
      companyKeepsPhysicalInventoryField.setValue(undefined);
      companyIssuesMoreThan50OutgoingInvoicesYearlyField.setValue(undefined);
      hasFiveOrMoreEmployeesField.setValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyActivelyOperatingField.value]);

  useEffect(() => {
    if (customerAlreadyHasCompanyField.value === "yes") {
      companyFoundingServiceField.setValue(false);
    } else {
      clearAddExistingCompanyFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAlreadyHasCompanyField.value]);

  useEffect(() => {
    if (legalFormField.value === LegalForm.AG) {
      lowTaxBrokerField.setValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalFormField.value]);

  const shouldUnselectAdminService =
    companyDoesNotFallIntoCategoriesField.value === "no" ||
    companyOwnsRealEstateField.value === "yes-more-than-15" ||
    companyOwnsRealEstateField.value === "yes-up-to-15" ||
    companyIssuesMoreThan50OutgoingInvoicesYearlyField.value === "yes" ||
    companyKeepsPhysicalInventoryField.value === "yes" ||
    hasFiveOrMoreEmployeesField.value === "yes" ||
    tradesCryptoField.value === "yes";

  useEffect(() => {
    if (shouldUnselectAdminService) {
      adminServiceField.setValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyDoesNotFallIntoCategoriesField.value,
    companyOwnsRealEstateField.value,
    companyOwnsRealEstateField.value,
    companyIssuesMoreThan50OutgoingInvoicesYearlyField.value,
    companyKeepsPhysicalInventoryField.value,
    hasFiveOrMoreEmployeesField.value,
    tradesCryptoField.value
  ]);

  const onSelectCompanyHandler = (company) => {
    legalFormField.setValue(company?.companyData.legalForm);
    registerCourtField.setValue(company?.companyData.registerCourt);
    registrationField.setValue(company?.companyData.registration);
    companyAddress.setValue(removeTypenames(company?.companyAddress));
    hasCorporateShareholdersField.setValue(
      company === undefined ? undefined : company.hasCorporateShareholders ? "yes" : "no"
    );
    northDataUniqueKeyField.setValue(company?.northDataUniqueKey);
    setFillCompanyInformationManually(false);
  };

  const selectedCompany = hasSelectedCompany
    ? {
        companyName: companyNameField.value,
        registration: registrationField.value,
        registerCourt: registerCourtField.value,
        legalForm: legalFormField.value,
        leiNumber: null
      }
    : undefined;

  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  return (
    <>
      {onlyBroker ? <></> : <DoYouHaveACompanyQuestion />}
      {customerAlreadyHasCompanyField.value === "yes" && useCompanySearchInput && (
        <ShortOrderFormCompanySearchInput
          t={t}
          onSelect={onSelectCompanyHandler}
          initialSelectedItem={selectedCompany}
          companyNotListedHandler={(show) => setFillCompanyInformationManually(show)}
        />
      )}
      {customerAlreadyHasCompanyField.value === "yes" && fillCompanyInformationManually && (
        <>
          <SelectYourCompanyLegalFormQuestion />
          <CorporateShareholdersQuestion />
        </>
      )}
      {!onlyBroker && customerAlreadyHasCompanyField.value === "yes" && (
        <FeatureFlag name={FeatureFlags.CompanyCategoryQuestionShortOrderForm}>
          <CompanyCategoriesQuestion />
          <RealStateQuestion />
          <CryptoTradingQuestion />
          <CompanyActivelyOperatingQuestion />
          <div className={`company-information-step__checkboxes`}>
            <RideCheckbox
              name="truthfulInformationProvided"
              formik={formik}
              shouldShowErrorFeedback={true}
              label={
                <Typography category="Paragraph" size={100} weight={"Light"}>
                  <Trans i18nKey="generic:i-acknowledge-information-is-true" />
                </Typography>
              }
            />
          </div>
        </FeatureFlag>
      )}
    </>
  );
};

export interface QuestionModalProps extends WithTranslation {
  onClose: () => void;
}

const CompanyInformationLayout = ({
  t,
  children,
  persistData,
  goToPreviousStep,
  formik
}: {
  t: TFunction;
  persistData: (values: UnifiedShortOrderFormValues) => void;
  goToPreviousStep: () => void;
  formik: any;
} & PropsWithChildren<{}>) => {
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  return (
    <StepLayout data-testid="company-information-step">
      <StepContent>
        {onlyBroker ? (
          <StepHeader
            data-testid={"company-information-step-header"}
            title={t("generic:short-order-form.company-information.title")}
            subtitle={
              <Trans
                i18nKey="generic:ride-broker-only-signup-order-form.company-information.subtitle"
                components={{ bold: <strong /> }}
              />
            }
            backButtonHandler={() => {
              persistData(formik.values);
              goToPreviousStep();
            }}
          />
        ) : (
          <StepHeader
            data-testid={"company-information-step-header"}
            title={t("generic:short-order-form.company-information.title")}
            subtitle={
              <Trans
                i18nKey="generic:short-order-form.company-information.subtitle"
                components={{ bold: <strong /> }}
              />
            }
            backButtonHandler={() => {
              persistData(formik.values);
              goToPreviousStep();
            }}
          />
        )}
        <div>{children}</div>
      </StepContent>
      <StepContinueButton label={t("generic:continue")} />
    </StepLayout>
  );
};

const ShortOrderFormCompanySearchInput = ({
  t,
  onSelect,
  companyNotListedHandler,
  initialSelectedItem
}: {
  onSelect: (
    data: ExternalCompanyDataByName_externalCompanyDataByName_companies | undefined
  ) => void;
  t: TFunction;
  initialSelectedItem?: WithoutTypenames<ExternalCompanyDataByName_externalCompanyDataByName_companies_companyData>;
  companyNotListedHandler: (show: boolean) => void;
}) => {
  const [selectedItem, setSelectedItem] = useState<
    | WithoutTypenames<ExternalCompanyDataByName_externalCompanyDataByName_companies_companyData>
    | undefined
  >(initialSelectedItem);

  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  return (
    <div>
      <div className={"company-information-step__your-company" + (onlyBroker ? " mt-0" : "")}>
        <BusinessIcon data-testid={"order-summary-header-icon"} />
        <Typography
          data-testid={"order-summary-header-title"}
          category="Paragraph"
          size={100}
          weight={"Heavy"}>
          {t("generic:short-order-form.company-information.your-company")}
        </Typography>
      </div>
      {selectedItem ? (
        <>
          <SelectedExternalCompanyCard
            externalCompanyData={selectedItem}
            onClose={() => {
              onSelect(undefined);
              setSelectedItem(undefined);
            }}
          />
          <ErrorMessageWithT name="companyLegalForm" extra={{}} />
        </>
      ) : (
        <div className={"company-information-step__company-search"}>
          <Typography
            category="Paragraph"
            size={100}
            weight={"Heavy"}
            data-testid="ride-company-search-label">
            {t("generic:short-order-form.company-information.company-search.title")}
          </Typography>
          <ExternalCompanySearchInputContainer
            onSelectCompany={(company) => {
              onSelect?.(company);
              setSelectedItem(company.companyData);
            }}
            inputName={"companyName"}
          />
          <Typography
            category="Paragraph"
            size={300}
            weight={"Light"}
            data-testid="ride-company-search-info"
            className={"company-information-step__company-search-info"}>
            {t("generic:short-order-form.company-information.company-search.info")}
          </Typography>
          <RideButton
            className={"company-information-step__company-not-listed"}
            data-testid={"company-not-in-the-list"}
            type="button"
            label={t("generic:short-order-form.company-information.company-not-in-the-list")}
            variant={RideButtonVariant.GHOST}
            size={RideButtonSize.SMALL}
            onClick={() => companyNotListedHandler(true)}
          />
        </div>
      )}
    </div>
  );
};

const CompanyInformationStepSchema = (t: TFunction, companyCategoryQuestionIsEnabled: boolean) => {
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  if (onlyBroker) {
    return Yup.object().shape({
      customerAlreadyHasCompany: Yup.string()
        .oneOf(["yes", "no"])
        .required(t("generic:validation-required")),
      companyName: Yup.string().when("customerAlreadyHasCompany", {
        is: "yes",
        then: (schema) => schema.required(t("generic:validation-required"))
      }),
      companyLegalForm: Yup.string()
        .oneOf(["GmbH", "UG", "AG"], t("generic:unsupported-company-legal-form"))
        .when("customerAlreadyHasCompany", {
          is: "yes",
          then: (schema) => schema.required(t("generic:validation-required"))
        })
        .nullable(),
      hasCorporateShareholders: Yup.string()
        .oneOf(["yes", "no"])
        .when("customerAlreadyHasCompany", {
          is: "yes",
          then: (schema) => schema.required(t("generic:validation-required"))
        }),
      companyDoesNotFallIntoCategories: Yup.string().notRequired(),
      companyOwnsRealEstate: Yup.string().notRequired(),
      tradesCrypto: Yup.string().notRequired(),
      companyActivelyOperating: Yup.string().notRequired(),
      companyKeepsPhysicalInventory: Yup.string().notRequired(),
      companyIssuesMoreThan50OutgoingInvoicesYearly: Yup.string().notRequired(),
      hasFiveOrMoreEmployees: Yup.string().notRequired(),
      truthfulInformationProvided: Yup.string().notRequired()
    });
  }

  return Yup.object().shape({
    customerAlreadyHasCompany: Yup.string()
      .oneOf(["yes", "no"])
      .required(t("generic:validation-required")),
    companyName: Yup.string().when("customerAlreadyHasCompany", {
      is: "yes",
      then: (schema) => schema.required(t("generic:validation-required"))
    }),
    companyLegalForm: Yup.string()
      .oneOf(["GmbH", "UG", "AG"], t("generic:unsupported-company-legal-form"))
      .when("customerAlreadyHasCompany", {
        is: "yes",
        then: (schema) => schema.required(t("generic:validation-required"))
      })
      .nullable(),
    hasCorporateShareholders: Yup.string()
      .oneOf(["yes", "no"])
      .when("customerAlreadyHasCompany", {
        is: "yes",
        then: (schema) => schema.required(t("generic:validation-required"))
      }),
    companyDoesNotFallIntoCategories: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes", "no"])
          .when("customerAlreadyHasCompany", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    companyOwnsRealEstate: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes-up-to-15", "yes-more-than-15", "no"])
          .when("customerAlreadyHasCompany", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    tradesCrypto: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes", "no"])
          .when("customerAlreadyHasCompany", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    companyActivelyOperating: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes", "no"])
          .when("customerAlreadyHasCompany", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    companyKeepsPhysicalInventory: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes", "no"])
          .when("companyActivelyOperating", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    companyIssuesMoreThan50OutgoingInvoicesYearly: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes", "no"])
          .when("companyActivelyOperating", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    hasFiveOrMoreEmployees: companyCategoryQuestionIsEnabled
      ? Yup.string()
          .oneOf(["yes", "no"])
          .when("companyActivelyOperating", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.string().notRequired(),
    truthfulInformationProvided: companyCategoryQuestionIsEnabled
      ? Yup.boolean()
          .isTrue(t("generic:validation-required"))
          .when("customerAlreadyHasCompany", {
            is: "yes",
            then: (schema) => schema.required(t("generic:validation-required"))
          })
      : Yup.boolean().notRequired()
  });
};

export default withTranslationReady(["generic"])(CompanyInformationStep);
