/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountingFrameworkEnum {
  SKR03 = "SKR03",
  SKR04 = "SKR04",
  SKR49 = "SKR49",
}

export enum ApiFeedback {
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  SUCCESS = "SUCCESS",
}

export enum AssetType {
  BrokerageAccountSet = "BrokerageAccountSet",
  Company = "Company",
  RealEstate = "RealEstate",
  Security = "Security",
}

export enum BankAccountPlan {
  Fyrst_Base = "Fyrst_Base",
  Fyrst_Complete = "Fyrst_Complete",
  Penta_Comfort = "Penta_Comfort",
  Penta_Starter = "Penta_Starter",
}

export enum BankType {
  Finom = "Finom",
  Fyrst = "Fyrst",
  Own = "Own",
  Penta = "Penta",
  Swan = "Swan",
}

export enum BrokerBankEnum {
  ActivTrades = "ActivTrades",
  AdmiralMarkets = "AdmiralMarkets",
  FxFlatMetaTrader4 = "FxFlatMetaTrader4",
  FxFlatMetaTrader5 = "FxFlatMetaTrader5",
  GBE = "GBE",
  IB = "IB",
  ICMarkets = "ICMarkets",
  JFD = "JFD",
  JPM = "JPM",
  LTB = "LTB",
  LTB2 = "LTB2",
  PM1 = "PM1",
  Pepperstone = "Pepperstone",
  RoboForex = "RoboForex",
  ThunderForex = "ThunderForex",
  VBank = "VBank",
  WhSelfInvestCFD = "WhSelfInvestCFD",
  WhSelfInvestFutures = "WhSelfInvestFutures",
}

export enum BrokerNameEnum {
  ActivTrades = "ActivTrades",
  AdmiralMarkets = "AdmiralMarkets",
  Banx = "Banx",
  Captrader = "Captrader",
  FXFlat = "FXFlat",
  FxFlatMetaTrader4 = "FxFlatMetaTrader4",
  FxFlatMetaTrader5 = "FxFlatMetaTrader5",
  GBE = "GBE",
  IB = "IB",
  ICMarkets = "ICMarkets",
  JFD = "JFD",
  JPM = "JPM",
  LTB = "LTB",
  LTB2 = "LTB2",
  Lynx = "Lynx",
  PM1 = "PM1",
  Pepperstone = "Pepperstone",
  Poolbroker = "Poolbroker",
  RoboForex = "RoboForex",
  ThunderForex = "ThunderForex",
  VBank = "VBank",
  WhSelfInvestCFD = "WhSelfInvestCFD",
  WhSelfInvestFutures = "WhSelfInvestFutures",
}

export enum BrokerageAccountValidityEnum {
  Deleted = "Deleted",
  Expired = "Expired",
  Invalid = "Invalid",
  New = "New",
  Processing = "Processing",
  ReportInvalid = "ReportInvalid",
  Valid = "Valid",
}

export enum BrokerageAccountValidityFilter {
  Deleted = "Deleted",
  Expired = "Expired",
  Invalid = "Invalid",
  New = "New",
  Processing = "Processing",
  ReportInvalid = "ReportInvalid",
  Valid = "Valid",
  ValidWithIssues = "ValidWithIssues",
}

export enum CompanyAssets {
  DERIVATIVES = "DERIVATIVES",
  HOLDING = "HOLDING",
  REAL_ESTATE = "REAL_ESTATE",
  SHARES_AND_FUNDS = "SHARES_AND_FUNDS",
}

export enum CompanyCreationStep {
  ADDRESS = "ADDRESS",
  ASSETS = "ASSETS",
  COMPANY = "COMPANY",
  DIRECTOR = "DIRECTOR",
  EMAIL_CONFIRMED = "EMAIL_CONFIRMED",
  EMPLOYEE_MANAGING_DIRECTOR = "EMPLOYEE_MANAGING_DIRECTOR",
  MANAGING_DIRECTOR = "MANAGING_DIRECTOR",
  ONBOARDED = "ONBOARDED",
  ONBOARDING = "ONBOARDING",
  OPERATIONAL_ACTIVITIES = "OPERATIONAL_ACTIVITIES",
  ORDER = "ORDER",
  ORDERED = "ORDERED",
  SELECT_PACKAGE = "SELECT_PACKAGE",
  SHAREHOLDERS = "SHAREHOLDERS",
  SHAREHOLDERS_DETAILS = "SHAREHOLDERS_DETAILS",
  SHARES = "SHARES",
}

export enum CompanyCreationTypeEnum {
  ADMIN_SERVICE = "ADMIN_SERVICE",
  COMPANY_FOUNDING = "COMPANY_FOUNDING",
}

export enum CompanyMigrationType {
  Default = "Default",
  PTAMigrationToRideSteuerberatung = "PTAMigrationToRideSteuerberatung",
  WePaStandalone3PTAMigrationToRideSteueberatung = "WePaStandalone3PTAMigrationToRideSteueberatung",
}

export enum CompanyOrderStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum CompanyTestType {
  Employees = "Employees",
  Shelf = "Shelf",
  Test = "Test",
}

export enum CompanyType {
  ClientEntity = "ClientEntity",
  Operating = "Operating",
  PersonalHolding = "PersonalHolding",
  Shelf = "Shelf",
  Spv = "Spv",
  TaxService = "TaxService",
  ThirdPartyTaxService = "ThirdPartyTaxService",
}

export enum Currency {
  EUR = "EUR",
}

export enum DiscountCodeAllowedServicesEnum {
  AS = "AS",
  GS = "GS",
}

export enum DiscountCodeStatus {
  CONFIRMED = "CONFIRMED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum DocumentType {
  IDCARD = "IDCARD",
}

export enum EmailValidity {
  ALREADY_EXISTS = "ALREADY_EXISTS",
  VALID = "VALID",
}

export enum ErrorCodes {
  ACCOUNT_VERIFICATION_INVALID = "ACCOUNT_VERIFICATION_INVALID",
  ACCOUNT_VERIFICATION_TOKEN_EXPIRED = "ACCOUNT_VERIFICATION_TOKEN_EXPIRED",
  ADDRESS_NOT_FOUND = "ADDRESS_NOT_FOUND",
  ADMIN_SERVICE_ALREADY_EXISTS = "ADMIN_SERVICE_ALREADY_EXISTS",
  ADMIN_SERVICE_DOES_NOT_EXIST = "ADMIN_SERVICE_DOES_NOT_EXIST",
  ADMIN_SERVICE_INVOICE_DATA_NOT_FOUND = "ADMIN_SERVICE_INVOICE_DATA_NOT_FOUND",
  ADMIN_SERVICE_MIGRATION_ORDER_DOES_NOT_EXIST = "ADMIN_SERVICE_MIGRATION_ORDER_DOES_NOT_EXIST",
  ADMIN_SERVICE_NOT_READY = "ADMIN_SERVICE_NOT_READY",
  ADMIN_SERVICE_STORAGE_STRUCTURE_NOT_CREATED = "ADMIN_SERVICE_STORAGE_STRUCTURE_NOT_CREATED",
  ADMIN_SERVICE_WITHOUT_TIER = "ADMIN_SERVICE_WITHOUT_TIER",
  ANNUAL_DETAILS_NOT_FOUND = "ANNUAL_DETAILS_NOT_FOUND",
  API_ACCESS_NOT_FOUND = "API_ACCESS_NOT_FOUND",
  ASSET_NOT_FOUND = "ASSET_NOT_FOUND",
  ASSET_WITH_BROKERAGE_ACCOUNT_SET_ALREADY_EXISTS = "ASSET_WITH_BROKERAGE_ACCOUNT_SET_ALREADY_EXISTS",
  ASSET_WITH_CLUB_DEAL_ALREADY_EXISTS = "ASSET_WITH_CLUB_DEAL_ALREADY_EXISTS",
  ASSET_WITH_COMPANY_ALREADY_EXISTS = "ASSET_WITH_COMPANY_ALREADY_EXISTS",
  ASSET_WITH_REAL_ESTATE_ALREADY_EXISTS = "ASSET_WITH_REAL_ESTATE_ALREADY_EXISTS",
  ASSET_WITH_SECURITY_ASSET_ALREADY_EXISTS = "ASSET_WITH_SECURITY_ASSET_ALREADY_EXISTS",
  ASSIGNED_COMPANY_NOT_FOUND = "ASSIGNED_COMPANY_NOT_FOUND",
  AT_LEAST_ONE_PRODUCT_MUST_BE_SELECTED = "AT_LEAST_ONE_PRODUCT_MUST_BE_SELECTED",
  AT_LEAST_ONE_SHAREHOLDER_MUST_BE_SELECTED = "AT_LEAST_ONE_SHAREHOLDER_MUST_BE_SELECTED",
  AUTH_OAUTH_CLIENT_NO_PERMISSION = "AUTH_OAUTH_CLIENT_NO_PERMISSION",
  AUTH_USER_EXPIRED = "AUTH_USER_EXPIRED",
  AUTH_USER_LOCKED = "AUTH_USER_LOCKED",
  AUTH_USER_TEMPORARY_LOCKED = "AUTH_USER_TEMPORARY_LOCKED",
  BANK_ACCOUNT_NOT_FOUND = "BANK_ACCOUNT_NOT_FOUND",
  BANK_ACCOUNT_TRANSACTION_NOT_FOUND = "BANK_ACCOUNT_TRANSACTION_NOT_FOUND",
  BROKERAGE_ACCOUNT_NOT_FOUND = "BROKERAGE_ACCOUNT_NOT_FOUND",
  BROKERAGE_ACCOUNT_SET_NOT_FOUND = "BROKERAGE_ACCOUNT_SET_NOT_FOUND",
  BROKERAGE_SURVEY_NOT_FOUND = "BROKERAGE_SURVEY_NOT_FOUND",
  BUCKET_NAME_NOT_FOUND = "BUCKET_NAME_NOT_FOUND",
  BUSINESS_ADDRESS_MISSING = "BUSINESS_ADDRESS_MISSING",
  CANNOT_DELETE_NON_EMPTY_FOLDER = "CANNOT_DELETE_NON_EMPTY_FOLDER",
  CHANGE_LTB_ORDER_EXTERNAL_VALIDATION_TYPE_FORBIDDEN = "CHANGE_LTB_ORDER_EXTERNAL_VALIDATION_TYPE_FORBIDDEN",
  CHECKOUT_SESSION_NOT_FOUND = "CHECKOUT_SESSION_NOT_FOUND",
  CLUB_DEAL_CREATION_FAILED = "CLUB_DEAL_CREATION_FAILED",
  CLUB_DEAL_NOT_FOUND = "CLUB_DEAL_NOT_FOUND",
  CODE_VERIFICATION_FAILED = "CODE_VERIFICATION_FAILED",
  COMPANY_CANNOT_BE_DIRECTOR = "COMPANY_CANNOT_BE_DIRECTOR",
  COMPANY_CREATION_FAILED = "COMPANY_CREATION_FAILED",
  COMPANY_CREATION_HAS_MISSING_FLOW_TYPE = "COMPANY_CREATION_HAS_MISSING_FLOW_TYPE",
  COMPANY_CREATION_NOT_FOUND = "COMPANY_CREATION_NOT_FOUND",
  COMPANY_EMAIL_ALREADY_EXISTS = "COMPANY_EMAIL_ALREADY_EXISTS",
  COMPANY_HOLDING_NOT_SUPPORTED = "COMPANY_HOLDING_NOT_SUPPORTED",
  COMPANY_LEGAL_FORM_MISSING = "COMPANY_LEGAL_FORM_MISSING",
  COMPANY_LEGAL_FORM_NOT_SUPPORTED = "COMPANY_LEGAL_FORM_NOT_SUPPORTED",
  COMPANY_LOCKED = "COMPANY_LOCKED",
  COMPANY_MIGRATION_NOT_FOUND = "COMPANY_MIGRATION_NOT_FOUND",
  COMPANY_NAME_MISSING = "COMPANY_NAME_MISSING",
  COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND",
  COMPANY_OWNER_CREATION_FAILED = "COMPANY_OWNER_CREATION_FAILED",
  COMPANY_ROOT_NODE_ALREADY_EXISTS = "COMPANY_ROOT_NODE_ALREADY_EXISTS",
  COMPANY_STEP_NOT_FOUND = "COMPANY_STEP_NOT_FOUND",
  COMPANY_UPDATE_FORBIDDEN = "COMPANY_UPDATE_FORBIDDEN",
  CONTRACT_ALREADY_SIGNED = "CONTRACT_ALREADY_SIGNED",
  CREATE_NEW_LTB_ORDER_EXTERNAL_VALIDATION_FOR_EXISTENT_TYPE_FORBIDDEN = "CREATE_NEW_LTB_ORDER_EXTERNAL_VALIDATION_FOR_EXISTENT_TYPE_FORBIDDEN",
  CRUD_COLLECTION_CREATION_ERROR = "CRUD_COLLECTION_CREATION_ERROR",
  CUSTOMER_NOT_FUNDED = "CUSTOMER_NOT_FUNDED",
  CUSTOMER_ON_SPECIAL_CASE_TIER = "CUSTOMER_ON_SPECIAL_CASE_TIER",
  DATA_INCONSISTENT_ERROR = "DATA_INCONSISTENT_ERROR",
  DEPENDENCY_INCORRECTLY_CONFIGURED = "DEPENDENCY_INCORRECTLY_CONFIGURED",
  DISCOUNT_CODE_ALREADY_USED_BY_COMPANY_AND_PERSON = "DISCOUNT_CODE_ALREADY_USED_BY_COMPANY_AND_PERSON",
  DISCOUNT_CODE_BLACKLISTED = "DISCOUNT_CODE_BLACKLISTED",
  DISCOUNT_CODE_NOT_FOUND = "DISCOUNT_CODE_NOT_FOUND",
  DISCOUNT_CODE_USE_NOT_FOUND = "DISCOUNT_CODE_USE_NOT_FOUND",
  DOCUMENT_ALREADY_EXISTS = "DOCUMENT_ALREADY_EXISTS",
  DOCUMENT_CREATION_FAILED = "DOCUMENT_CREATION_FAILED",
  DOCUMENT_NOT_FOUND = "DOCUMENT_NOT_FOUND",
  DOCUMENT_NOT_SIGNED_YET = "DOCUMENT_NOT_SIGNED_YET",
  DRACOON_BAD_GATEWAY = "DRACOON_BAD_GATEWAY",
  DRACOON_BAD_REQUEST = "DRACOON_BAD_REQUEST",
  DRACOON_GATEWAY_TIMEOUT = "DRACOON_GATEWAY_TIMEOUT",
  DRACOON_INSUFFICIENT_STORAGE = "DRACOON_INSUFFICIENT_STORAGE",
  DRACOON_MAINTENANCE = "DRACOON_MAINTENANCE",
  DRACOON_MALWARE_FOUND = "DRACOON_MALWARE_FOUND",
  DRACOON_NODE_ID_IS_NAN = "DRACOON_NODE_ID_IS_NAN",
  DRACOON_PAYMENT_REQUIRED = "DRACOON_PAYMENT_REQUIRED",
  DRACOON_PERMISSION_FORBIDDEN = "DRACOON_PERMISSION_FORBIDDEN",
  DRACOON_PRECONDITION_FAILED = "DRACOON_PRECONDITION_FAILED",
  DRACOON_TOO_MANY_ATTEMPTS = "DRACOON_TOO_MANY_ATTEMPTS",
  DRACOON_TOO_MANY_REQUESTS = "DRACOON_TOO_MANY_REQUESTS",
  DRACOON_UNAUTHORIZED = "DRACOON_UNAUTHORIZED",
  DUPLICATE_FILE_NAME = "DUPLICATE_FILE_NAME",
  EMAIL_ACCOUNT_ALREADY_EXISTS = "EMAIL_ACCOUNT_ALREADY_EXISTS",
  EMAIL_ACCOUNT_EXISTS_RESUME_EMAIL_SENT = "EMAIL_ACCOUNT_EXISTS_RESUME_EMAIL_SENT",
  EMAIL_EXISTS_RECOMMEND_LOGIN_INSTEAD = "EMAIL_EXISTS_RECOMMEND_LOGIN_INSTEAD",
  EMAIL_NOT_PROVIDED = "EMAIL_NOT_PROVIDED",
  EMAIL_TEMPLATE_NOT_FOUND = "EMAIL_TEMPLATE_NOT_FOUND",
  ERROR_FETCHING_FORM = "ERROR_FETCHING_FORM",
  EVENT_ACTOR_NOT_FOUND = "EVENT_ACTOR_NOT_FOUND",
  EXTERNAL_STORAGE_IS_NOT_INITIALIZED = "EXTERNAL_STORAGE_IS_NOT_INITIALIZED",
  EXTERNAL_STORAGE_UNDEFINED_FOR_COMPANY_TYPE = "EXTERNAL_STORAGE_UNDEFINED_FOR_COMPANY_TYPE",
  EXTERNAL_STORAGE_USER_ALREADY_EXISTS = "EXTERNAL_STORAGE_USER_ALREADY_EXISTS",
  EXTERNAL_STORAGE_USER_NOT_FOUND = "EXTERNAL_STORAGE_USER_NOT_FOUND",
  FAILED_TO_GENERATE_DISCOUNT_CODE = "FAILED_TO_GENERATE_DISCOUNT_CODE",
  FAILED_TO_GENERATE_WEPA_ID = "FAILED_TO_GENERATE_WEPA_ID",
  FAILED_TO_SET_WEPA_BOOKING_FLAG = "FAILED_TO_SET_WEPA_BOOKING_FLAG",
  FAILED_TO_SYNC_DEAL = "FAILED_TO_SYNC_DEAL",
  FAILED_TO_UPDATE_TWO_FACTOR_AUTH_ACTIVATION = "FAILED_TO_UPDATE_TWO_FACTOR_AUTH_ACTIVATION",
  FEATURE_FLAG_BASE_PATH_IS_NOT_DEFINED = "FEATURE_FLAG_BASE_PATH_IS_NOT_DEFINED",
  FIELD_IS_REQUIRED = "FIELD_IS_REQUIRED",
  FILE_EXTENSION_DOES_NOT_MATCH = "FILE_EXTENSION_DOES_NOT_MATCH",
  FILE_NOT_FOUND = "FILE_NOT_FOUND",
  FINANCE_ADS_BAD_REQUEST = "FINANCE_ADS_BAD_REQUEST",
  FINANCIAL_DATA_BANK_EXISTS_FOR_USER = "FINANCIAL_DATA_BANK_EXISTS_FOR_USER",
  FINANCIAL_DATA_BANK_NOT_FOUND = "FINANCIAL_DATA_BANK_NOT_FOUND",
  FINANCIAL_DATA_MULTIPLE_BANKS_FOUND = "FINANCIAL_DATA_MULTIPLE_BANKS_FOUND",
  FINANCIAL_DATA_PSD2_WEBFORM_NOT_REQUIRED = "FINANCIAL_DATA_PSD2_WEBFORM_NOT_REQUIRED",
  FINANCIAL_DATA_UNAUTHORIZED = "FINANCIAL_DATA_UNAUTHORIZED",
  FINAPI_ACCOUNT_NOT_FOUND = "FINAPI_ACCOUNT_NOT_FOUND",
  FIN_API_BANK_CONNECTION_NOT_FOUND = "FIN_API_BANK_CONNECTION_NOT_FOUND",
  FISCAL_YEAR_ALREADY_EXIST = "FISCAL_YEAR_ALREADY_EXIST",
  FISCAL_YEAR_NOT_FOUND = "FISCAL_YEAR_NOT_FOUND",
  FISCAL_YEAR_NOT_WELL_FORMATTED = "FISCAL_YEAR_NOT_WELL_FORMATTED",
  FORMER_CLIENT_ASSIGNMENT_NOT_FOUND = "FORMER_CLIENT_ASSIGNMENT_NOT_FOUND",
  FORM_SUBMISSION_FAILED = "FORM_SUBMISSION_FAILED",
  GENDER_NOT_SUPPORTED = "GENDER_NOT_SUPPORTED",
  GMBH_ORDER_ADDRESS_INFORMATION_MISSING = "GMBH_ORDER_ADDRESS_INFORMATION_MISSING",
  GMBH_ORDER_ASSET_NOT_INFORMED = "GMBH_ORDER_ASSET_NOT_INFORMED",
  GMBH_ORDER_BIRTH_CITY_MISSING = "GMBH_ORDER_BIRTH_CITY_MISSING",
  GMBH_ORDER_CITIZENSHIP_MISSING = "GMBH_ORDER_CITIZENSHIP_MISSING",
  GMBH_ORDER_COMPANY_REGISTER_COURT_MISSING = "GMBH_ORDER_COMPANY_REGISTER_COURT_MISSING",
  GMBH_ORDER_COMPANY_REGISTRATION_MISSING = "GMBH_ORDER_COMPANY_REGISTRATION_MISSING",
  GMBH_ORDER_COUNTRY_NOT_SUPPORTED = "GMBH_ORDER_COUNTRY_NOT_SUPPORTED",
  GMBH_ORDER_CURRENT_USER_NOT_SHAREHOLDER = "GMBH_ORDER_CURRENT_USER_NOT_SHAREHOLDER",
  GMBH_ORDER_EMPLOYEE_IS_NOT_MANAGING_DIRECTOR = "GMBH_ORDER_EMPLOYEE_IS_NOT_MANAGING_DIRECTOR",
  GMBH_ORDER_GENDER_MISSING = "GMBH_ORDER_GENDER_MISSING",
  GMBH_ORDER_NO_MANAGING_DIRECTOR = "GMBH_ORDER_NO_MANAGING_DIRECTOR",
  GMBH_ORDER_REUSED_EMAIL = "GMBH_ORDER_REUSED_EMAIL",
  GMBH_ORDER_SHAREHOLDER_TYPE_NOT_SUPPORTED = "GMBH_ORDER_SHAREHOLDER_TYPE_NOT_SUPPORTED",
  HOLDING_COMPANY_ALREADY_EXISTS = "HOLDING_COMPANY_ALREADY_EXISTS",
  HOLDING_HAS_ASSETS_UNABLE_TO_DELETE = "HOLDING_HAS_ASSETS_UNABLE_TO_DELETE",
  HOLDING_HAS_WALLET_UNABLE_TO_DELETE = "HOLDING_HAS_WALLET_UNABLE_TO_DELETE",
  HUBSPOT_CONTACT_FIELD_MISSING = "HUBSPOT_CONTACT_FIELD_MISSING",
  HUBSPOT_CONTACT_NOT_FOUND = "HUBSPOT_CONTACT_NOT_FOUND",
  IBAN_NOT_PROVIDED = "IBAN_NOT_PROVIDED",
  IBAN_VALIDATION_ERROR = "IBAN_VALIDATION_ERROR",
  IBKR_APPLICATION_GENERAL_FAILURE = "IBKR_APPLICATION_GENERAL_FAILURE",
  IBKR_COMMUNICATION_FAILURE = "IBKR_COMMUNICATION_FAILURE",
  ID_FIELD_IS_REQUIRED = "ID_FIELD_IS_REQUIRED",
  IMPROPER_DISCOUNT_CODE_USAGE_STATUS_TRANSITION = "IMPROPER_DISCOUNT_CODE_USAGE_STATUS_TRANSITION",
  INCORRECT_USER_NEW_PASSWORD = "INCORRECT_USER_NEW_PASSWORD",
  INCORRECT_USER_PASSWORD = "INCORRECT_USER_PASSWORD",
  INTERNAL_WEPA_ERROR = "INTERNAL_WEPA_ERROR",
  INVALID_AMOUNT = "INVALID_AMOUNT",
  INVALID_CACHE_EXPIRATION = "INVALID_CACHE_EXPIRATION",
  INVALID_CACHE_VALUE = "INVALID_CACHE_VALUE",
  INVALID_DATE_FORMAT = "INVALID_DATE_FORMAT",
  INVALID_EMAIL = "INVALID_EMAIL",
  INVALID_FILE_NAME = "INVALID_FILE_NAME",
  INVALID_FILE_UPLOAD = "INVALID_FILE_UPLOAD",
  INVALID_GMBH_ORDER_STRUCTURE = "INVALID_GMBH_ORDER_STRUCTURE",
  INVALID_LEI_NUMBER = "INVALID_LEI_NUMBER",
  INVALID_PARAMETER_EMAIL_ERROR = "INVALID_PARAMETER_EMAIL_ERROR",
  INVALID_PARAMETER_RESUME_LINK_ERROR = "INVALID_PARAMETER_RESUME_LINK_ERROR",
  INVALID_PARAMETER_SIGNUP_TOKEN_ERROR = "INVALID_PARAMETER_SIGNUP_TOKEN_ERROR",
  INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
  INVALID_PRODUCT = "INVALID_PRODUCT",
  INVALID_SHAREHOLDER_COUNT = "INVALID_SHAREHOLDER_COUNT",
  INVALID_STATUS_FOR_LEI_UPDATE = "INVALID_STATUS_FOR_LEI_UPDATE",
  INVALID_STORAGE_CONFIG_DUPLICATE_REF = "INVALID_STORAGE_CONFIG_DUPLICATE_REF",
  INVALID_STRIPE_SESSION_ID = "INVALID_STRIPE_SESSION_ID",
  INVALID_UPDATE_EMAIL_TOKEN = "INVALID_UPDATE_EMAIL_TOKEN",
  INVALID_URL = "INVALID_URL",
  INVALID_USER_ROLE_FOR_INVITE_EMAIL = "INVALID_USER_ROLE_FOR_INVITE_EMAIL",
  INVALID_VALUE = "INVALID_VALUE",
  INVALID_WEPA_REQUEST = "INVALID_WEPA_REQUEST",
  INVESTMENT_ALREADY_EXISTS = "INVESTMENT_ALREADY_EXISTS",
  INVESTMENT_NOT_FOUND = "INVESTMENT_NOT_FOUND",
  INVITE_USER_FORBIDDEN = "INVITE_USER_FORBIDDEN",
  INVOICE_DATA_NOT_FOUND = "INVOICE_DATA_NOT_FOUND",
  KYC_ENTRY_NOT_FOUND = "KYC_ENTRY_NOT_FOUND",
  KYC_ENTRY_STATUS_NOT_COMPLETED = "KYC_ENTRY_STATUS_NOT_COMPLETED",
  LEGAL_PERSON_WITH_COMPANY_ALREADY_EXISTS = "LEGAL_PERSON_WITH_COMPANY_ALREADY_EXISTS",
  LEGAL_PERSON_WITH_PERSON_ALREADY_EXISTS = "LEGAL_PERSON_WITH_PERSON_ALREADY_EXISTS",
  LEI_NUMBER_ALREADY_EXISTS = "LEI_NUMBER_ALREADY_EXISTS",
  LOGIN_FAILED_UNAUTHORIZED = "LOGIN_FAILED_UNAUTHORIZED",
  LOGIN_FAILED_USER_LOCKED = "LOGIN_FAILED_USER_LOCKED",
  LOGIN_FAILED_USER_NOT_VERIFIED = "LOGIN_FAILED_USER_NOT_VERIFIED",
  LOGIN_TOKEN_REFRESH_FAILED = "LOGIN_TOKEN_REFRESH_FAILED",
  LOW_TAX_BROKER_CURRENT_USER_EMAIL_IS_IN_MORE_THAN_1_SHAREHOLDER = "LOW_TAX_BROKER_CURRENT_USER_EMAIL_IS_IN_MORE_THAN_1_SHAREHOLDER",
  LOW_TAX_BROKER_CURRENT_USER_IS_NOT_A_SHAREHOLDER_OR_DIRECTOR = "LOW_TAX_BROKER_CURRENT_USER_IS_NOT_A_SHAREHOLDER_OR_DIRECTOR",
  LOW_TAX_BROKER_CURRENT_USER_NOT_SHAREHOLDER = "LOW_TAX_BROKER_CURRENT_USER_NOT_SHAREHOLDER",
  LTB_ORDERER_CANNOT_BE_US_CITIZEN = "LTB_ORDERER_CANNOT_BE_US_CITIZEN",
  LTB_ORDERER_CAN_NOT_TRADE_ASSET = "LTB_ORDERER_CAN_NOT_TRADE_ASSET",
  LTB_ORDERER_DID_NOT_AGREE_WITH_CONSENT_TO_HANDLE_PERSONAL_DATA = "LTB_ORDERER_DID_NOT_AGREE_WITH_CONSENT_TO_HANDLE_PERSONAL_DATA",
  LTB_ORDER_EXTERNAL_VALIDATION_NOT_FOUND = "LTB_ORDER_EXTERNAL_VALIDATION_NOT_FOUND",
  MAIL_SENDER_CONFIGURATION_INVALID = "MAIL_SENDER_CONFIGURATION_INVALID",
  MAINTENANCE_MODE = "MAINTENANCE_MODE",
  MISSING_ACCOUNT_NUMBER_BROKERAGE_ACCOUNT = "MISSING_ACCOUNT_NUMBER_BROKERAGE_ACCOUNT",
  MISSING_ADDRESS_CITY = "MISSING_ADDRESS_CITY",
  MISSING_ADDRESS_COUNTRY = "MISSING_ADDRESS_COUNTRY",
  MISSING_ADDRESS_POSTAL_CODE = "MISSING_ADDRESS_POSTAL_CODE",
  MISSING_ADDRESS_STREET_LINE_1 = "MISSING_ADDRESS_STREET_LINE_1",
  MISSING_ARGS = "MISSING_ARGS",
  MISSING_AUTHORIZATION = "MISSING_AUTHORIZATION",
  MISSING_COMPANY_FULL_NAME = "MISSING_COMPANY_FULL_NAME",
  MISSING_COMPANY_LEGAL_FORM = "MISSING_COMPANY_LEGAL_FORM",
  MISSING_COMPANY_NAME = "MISSING_COMPANY_NAME",
  MISSING_COMPANY_REGISTRY_NUMBER = "MISSING_COMPANY_REGISTRY_NUMBER",
  MISSING_CONFIGURATION = "MISSING_CONFIGURATION",
  MISSING_DIRECTOR = "MISSING_DIRECTOR",
  MISSING_EMAIL = "MISSING_EMAIL",
  MISSING_FIRST_NAME = "MISSING_FIRST_NAME",
  MISSING_HOLDING_COMPANY = "MISSING_HOLDING_COMPANY",
  MISSING_LAST_NAME = "MISSING_LAST_NAME",
  MISSING_PHONE_NUMBER = "MISSING_PHONE_NUMBER",
  MISSING_QUERY_ID_BROKERAGE_ACCOUNT = "MISSING_QUERY_ID_BROKERAGE_ACCOUNT",
  MISSING_SCHEMA = "MISSING_SCHEMA",
  MISSING_SHAREHOLDERS_COUNT = "MISSING_SHAREHOLDERS_COUNT",
  MISSING_TOKEN_BROKERAGE_ACCOUNT = "MISSING_TOKEN_BROKERAGE_ACCOUNT",
  MULTIPLE_COMPANIES_FOUND = "MULTIPLE_COMPANIES_FOUND",
  MULTIPLE_LOW_TAX_BROKER_ORDERS_FOUND = "MULTIPLE_LOW_TAX_BROKER_ORDERS_FOUND",
  MULTIPLE_WEPA_STANDALONE_MIGRATION_ORDERS_FOUND = "MULTIPLE_WEPA_STANDALONE_MIGRATION_ORDERS_FOUND",
  NETWORK_ERROR = "NETWORK_ERROR",
  NLV_HISTORY_NOT_FOUND = "NLV_HISTORY_NOT_FOUND",
  NLV_NOT_FOUND = "NLV_NOT_FOUND",
  NODE_ALREADY_EXISTS = "NODE_ALREADY_EXISTS",
  NODE_NOT_FOUND = "NODE_NOT_FOUND",
  NODE_RENAME_FAILED = "NODE_RENAME_FAILED",
  NODE_SHOULD_BE_ROOM = "NODE_SHOULD_BE_ROOM",
  NOTARY_GROUP_NOT_FOUND = "NOTARY_GROUP_NOT_FOUND",
  NOTARY_LINKS_MISSING = "NOTARY_LINKS_MISSING",
  NOTARY_LINK_NOT_FOUND = "NOTARY_LINK_NOT_FOUND",
  NOTARY_MISSING_FIRST_NAME = "NOTARY_MISSING_FIRST_NAME",
  NOTARY_MISSING_LAST_NAME = "NOTARY_MISSING_LAST_NAME",
  NOTARY_MUST_HAVE_ONE_COMPANY = "NOTARY_MUST_HAVE_ONE_COMPANY",
  NOTARY_SERVICE_FIRST_PARTY_NOTARY_REQUIRED = "NOTARY_SERVICE_FIRST_PARTY_NOTARY_REQUIRED",
  NOTARY_SERVICE_MUST_HAVE_NOTARY_PERSON = "NOTARY_SERVICE_MUST_HAVE_NOTARY_PERSON",
  NOTARY_SERVICE_NOT_FOUND = "NOTARY_SERVICE_NOT_FOUND",
  NOT_ALLOWED_TO_ASSIGN_ORDER = "NOT_ALLOWED_TO_ASSIGN_ORDER",
  NOT_A_RIDE_BROKER_ORDER = "NOT_A_RIDE_BROKER_ORDER",
  NOT_CLIENT_COMPANY = "NOT_CLIENT_COMPANY",
  NOT_PARTNER_TAX_COMPANY = "NOT_PARTNER_TAX_COMPANY",
  NOT_SUPPORTED_YET = "NOT_SUPPORTED_YET",
  NOT_THIRD_PARTY_TAX_COMPANY = "NOT_THIRD_PARTY_TAX_COMPANY",
  NO_FISCAL_YEARS_PROVIDED = "NO_FISCAL_YEARS_PROVIDED",
  ORDER_ALREADY_ASSIGNED = "ORDER_ALREADY_ASSIGNED",
  ORDER_DELETED = "ORDER_DELETED",
  ORDER_MISSING_ASSET_EXPERIENCE = "ORDER_MISSING_ASSET_EXPERIENCE",
  ORDER_MISSING_COMPANY_ADDRESS = "ORDER_MISSING_COMPANY_ADDRESS",
  ORDER_MISSING_COMPANY_EMAIL = "ORDER_MISSING_COMPANY_EMAIL",
  ORDER_MISSING_COMPANY_TAX_ID = "ORDER_MISSING_COMPANY_TAX_ID",
  ORDER_MISSING_EMAIL = "ORDER_MISSING_EMAIL",
  ORDER_MISSING_FINANCIAL_INFORMATION = "ORDER_MISSING_FINANCIAL_INFORMATION",
  ORDER_MISSING_FIRST_NAME = "ORDER_MISSING_FIRST_NAME",
  ORDER_MISSING_LAST_NAME = "ORDER_MISSING_LAST_NAME",
  ORDER_MISSING_OWNER_ID = "ORDER_MISSING_OWNER_ID",
  ORDER_MISSING_PHONE_NUMBER = "ORDER_MISSING_PHONE_NUMBER",
  ORDER_MISSING_SHAREHOLDERS = "ORDER_MISSING_SHAREHOLDERS",
  ORDER_MISSING_SOURCES_OF_WEALTH_DESCRIPTION = "ORDER_MISSING_SOURCES_OF_WEALTH_DESCRIPTION",
  ORDER_MISSING_SOURCE_OF_WEALTH = "ORDER_MISSING_SOURCE_OF_WEALTH",
  ORDER_MISSING_TRADING_INTENTION = "ORDER_MISSING_TRADING_INTENTION",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_SHAREHOLDER_MISSING_EMPLOYMENT_DETAILS = "ORDER_SHAREHOLDER_MISSING_EMPLOYMENT_DETAILS",
  ORDER_SHAREHOLDER_MISSING_IS_MANAGING_DIRECTOR = "ORDER_SHAREHOLDER_MISSING_IS_MANAGING_DIRECTOR",
  ORDER_SHAREHOLDER_MISSING_PERSON_ADDRESS = "ORDER_SHAREHOLDER_MISSING_PERSON_ADDRESS",
  ORDER_SHAREHOLDER_MISSING_PERSON_CITIZENSHIP = "ORDER_SHAREHOLDER_MISSING_PERSON_CITIZENSHIP",
  ORDER_SHAREHOLDER_MISSING_PERSON_INFORMATION = "ORDER_SHAREHOLDER_MISSING_PERSON_INFORMATION",
  ORDER_SHAREHOLDER_MISSING_SHARES = "ORDER_SHAREHOLDER_MISSING_SHARES",
  ORDER_SHAREHOLDER_MISSING_STATUS_INFORMATION = "ORDER_SHAREHOLDER_MISSING_STATUS_INFORMATION",
  OWNER_NOT_FOUND = "OWNER_NOT_FOUND",
  PASSWORD_CHANGE_ERROR = "PASSWORD_CHANGE_ERROR",
  PASSWORD_CHANGE_FORBIDDEN = "PASSWORD_CHANGE_FORBIDDEN",
  PAYMENT_CUSTOMER_NOT_FOUND = "PAYMENT_CUSTOMER_NOT_FOUND",
  PAYMENT_CUSTOMER_NOT_IN_PLATFORM = "PAYMENT_CUSTOMER_NOT_IN_PLATFORM",
  PAYMENT_INVOICE_NOT_FOUND = "PAYMENT_INVOICE_NOT_FOUND",
  PAYMENT_PLATFORM_SUBSCRIPTION_NOT_FOUND = "PAYMENT_PLATFORM_SUBSCRIPTION_NOT_FOUND",
  PAYROLL_INVOICE_DATA_NOT_FOUND = "PAYROLL_INVOICE_DATA_NOT_FOUND",
  PENDING_INVITATION_NOT_FOUND = "PENDING_INVITATION_NOT_FOUND",
  PERSONS_CANNOT_REFER_THEMSELVES = "PERSONS_CANNOT_REFER_THEMSELVES",
  PERSON_ADDRESS_MISSING = "PERSON_ADDRESS_MISSING",
  PERSON_CANNOT_BE_CREATED = "PERSON_CANNOT_BE_CREATED",
  PERSON_LOCKED = "PERSON_LOCKED",
  PERSON_NOT_FOUND = "PERSON_NOT_FOUND",
  PERSON_ROOT_NODE_ALREADY_EXISTS = "PERSON_ROOT_NODE_ALREADY_EXISTS",
  PROCESS_STEP_NOT_FOUND = "PROCESS_STEP_NOT_FOUND",
  PROCESS_TASK_CREATION_ERROR = "PROCESS_TASK_CREATION_ERROR",
  PROCESS_TASK_NOT_FOUND = "PROCESS_TASK_NOT_FOUND",
  PROCESS_TASK_UPDATE_ERROR = "PROCESS_TASK_UPDATE_ERROR",
  PRODUCT_ORDER_SERVICE_PRODUCT_TYPE_NOT_SUPPORTED = "PRODUCT_ORDER_SERVICE_PRODUCT_TYPE_NOT_SUPPORTED",
  REALESTATE_CREATION_FAILED = "REALESTATE_CREATION_FAILED",
  REALESTATE_NOT_FOUND = "REALESTATE_NOT_FOUND",
  REALESTATE_OWNER_CREATION_FAILED = "REALESTATE_OWNER_CREATION_FAILED",
  REALESTATE_OWNER_NOT_FOUND = "REALESTATE_OWNER_NOT_FOUND",
  REAL_ESTATE_ADDRESS_MISSING = "REAL_ESTATE_ADDRESS_MISSING",
  REDIRECT_UNAUTHORIZED = "REDIRECT_UNAUTHORIZED",
  REFERRAL_CODE_NOT_ALLOWED_FOR_THIS_SERVICE = "REFERRAL_CODE_NOT_ALLOWED_FOR_THIS_SERVICE",
  REGISTRATION_FORBIDDEN = "REGISTRATION_FORBIDDEN",
  REQUEST_IP_ADDRESS_NOT_DEFINED = "REQUEST_IP_ADDRESS_NOT_DEFINED",
  RESUME_TOKEN_ERROR = "RESUME_TOKEN_ERROR",
  RESUME_TOKEN_EXPIRED = "RESUME_TOKEN_EXPIRED",
  RESUME_TOKEN_INVALID = "RESUME_TOKEN_INVALID",
  RESUME_TOKEN_NOT_FOUND = "RESUME_TOKEN_NOT_FOUND",
  RIDE_BROKER_MISSING_IBKR_USERNAME = "RIDE_BROKER_MISSING_IBKR_USERNAME",
  S3_CONNECTION_FAILED = "S3_CONNECTION_FAILED",
  S3_UPLOAD_ID_NOT_FOUND = "S3_UPLOAD_ID_NOT_FOUND",
  SECURITY_ASSET_CANNOT_BE_CREATED = "SECURITY_ASSET_CANNOT_BE_CREATED",
  SECURITY_ASSET_NOT_FOUND = "SECURITY_ASSET_NOT_FOUND",
  SECURITY_ASSET_STATS_CANNOT_BE_CREATED = "SECURITY_ASSET_STATS_CANNOT_BE_CREATED",
  SEPA_PAYMENT_METHOD_DOES_NOT_EXIST = "SEPA_PAYMENT_METHOD_DOES_NOT_EXIST",
  SHAREHOLDER_COMPANY_ID_IS_MISSING = "SHAREHOLDER_COMPANY_ID_IS_MISSING",
  SHAREHOLDER_COMPANY_NOT_FOUND = "SHAREHOLDER_COMPANY_NOT_FOUND",
  SHAREHOLDER_ENTITY_ID_IS_MISSING = "SHAREHOLDER_ENTITY_ID_IS_MISSING",
  SHARES_MISSING = "SHARES_MISSING",
  SHARES_TOTAL_MUST_BE_100 = "SHARES_TOTAL_MUST_BE_100",
  SHELF_COMPANY_NOT_FOUND = "SHELF_COMPANY_NOT_FOUND",
  SIGNUP_ERROR = "SIGNUP_ERROR",
  SIGNUP_FORBIDDEN = "SIGNUP_FORBIDDEN",
  SIGNUP_TOKEN_EXPIRED = "SIGNUP_TOKEN_EXPIRED",
  STRIPE_ACCOUNT_NOT_FOUND = "STRIPE_ACCOUNT_NOT_FOUND",
  STRIPE_CREDIT_NOTE_CREATION_FAILED = "STRIPE_CREDIT_NOTE_CREATION_FAILED",
  STRIPE_CUSTOMER_NOT_FOUND = "STRIPE_CUSTOMER_NOT_FOUND",
  STRIPE_CUSTOMER_WITH_DIFFERENT_NAME = "STRIPE_CUSTOMER_WITH_DIFFERENT_NAME",
  STRIPE_DATA_MISSING_IN_WEBHOOK_REQUEST = "STRIPE_DATA_MISSING_IN_WEBHOOK_REQUEST",
  STRIPE_INVALID_INVOICE_STATUS_FOR_CREDIT_NOTE_CREATION = "STRIPE_INVALID_INVOICE_STATUS_FOR_CREDIT_NOTE_CREATION",
  STRIPE_INVOICE_NOT_FOUND = "STRIPE_INVOICE_NOT_FOUND",
  STRIPE_UPDATE_ERROR = "STRIPE_UPDATE_ERROR",
  SUBCRIPTION_NOT_FOUND = "SUBCRIPTION_NOT_FOUND",
  SUPERNOMINEE_RESTRICTED_FEATURE = "SUPERNOMINEE_RESTRICTED_FEATURE",
  SURVEY_ANSWER_DESCRIPTION_NOT_PROVIDED = "SURVEY_ANSWER_DESCRIPTION_NOT_PROVIDED",
  SURVEY_ANSWER_NOT_PROVIDED = "SURVEY_ANSWER_NOT_PROVIDED",
  SWAN_REQUEST_FAILED = "SWAN_REQUEST_FAILED",
  TANGANY_ASYNC_TIMEOUT = "TANGANY_ASYNC_TIMEOUT",
  TANGANY_WALLET_NOT_FOUND = "TANGANY_WALLET_NOT_FOUND",
  TAX_ADVISOR_CODE_NOT_GENERATED = "TAX_ADVISOR_CODE_NOT_GENERATED",
  TAX_ADVISOR_MISSING_COMPANY_NAME = "TAX_ADVISOR_MISSING_COMPANY_NAME",
  TAX_ADVISOR_MISSING_EMAIL = "TAX_ADVISOR_MISSING_EMAIL",
  TAX_ADVISOR_MISSING_FIRST_NAME = "TAX_ADVISOR_MISSING_FIRST_NAME",
  TAX_ADVISOR_MISSING_LAST_NAME = "TAX_ADVISOR_MISSING_LAST_NAME",
  TAX_ADVISOR_NOT_THIRD_PARTY = "TAX_ADVISOR_NOT_THIRD_PARTY",
  TAX_SERVICE_ALREADY_ACTIVE = "TAX_SERVICE_ALREADY_ACTIVE",
  TAX_SERVICE_NOT_ACTIVE = "TAX_SERVICE_NOT_ACTIVE",
  TAX_SERVICE_NOT_FOUND = "TAX_SERVICE_NOT_FOUND",
  TAX_SERVICE_STORAGE_USER_NOT_CREATED = "TAX_SERVICE_STORAGE_USER_NOT_CREATED",
  TEMPLATE_ROOT_FOLDER_IS_NOT_INITIALIZED = "TEMPLATE_ROOT_FOLDER_IS_NOT_INITIALIZED",
  TERMS_AND_CONDITIONS_NOT_ACCEPTED = "TERMS_AND_CONDITIONS_NOT_ACCEPTED",
  THIRD_PARTY_COMPANY_ALREADY_HAS_SUB_CONTRACT = "THIRD_PARTY_COMPANY_ALREADY_HAS_SUB_CONTRACT",
  THIRD_PARTY_TAX_ADVISOR_NOT_FOUND = "THIRD_PARTY_TAX_ADVISOR_NOT_FOUND",
  TOKENIZED_INVESTMENT_ALREADY_EXISTS = "TOKENIZED_INVESTMENT_ALREADY_EXISTS",
  TOKENIZED_INVESTMENT_NOT_FOUND = "TOKENIZED_INVESTMENT_NOT_FOUND",
  TOO_MANY_DIRECTORS = "TOO_MANY_DIRECTORS",
  TOO_MANY_HOLDING_COMPANIES = "TOO_MANY_HOLDING_COMPANIES",
  UNABLE_TO_COMPANIES_WITH_DETAILS = "UNABLE_TO_COMPANIES_WITH_DETAILS",
  UNABLE_TO_COMPANY_BY_ID = "UNABLE_TO_COMPANY_BY_ID",
  UNABLE_TO_CONFIRM_VISIT = "UNABLE_TO_CONFIRM_VISIT",
  UNABLE_TO_CREATE_VISIT = "UNABLE_TO_CREATE_VISIT",
  UNABLE_TO_REDIRECT_VISIT = "UNABLE_TO_REDIRECT_VISIT",
  UNABLE_TO_TAX_ADVISOR_COMPANIES_MINIMAL = "UNABLE_TO_TAX_ADVISOR_COMPANIES_MINIMAL",
  UNABLE_TO_TAX_ADVISOR_COMPANY_NAMES = "UNABLE_TO_TAX_ADVISOR_COMPANY_NAMES",
  UNABLE_TO_TAX_ADVISOR_COMPANY_WITH_CLIENTS = "UNABLE_TO_TAX_ADVISOR_COMPANY_WITH_CLIENTS",
  UNAUTHORIZED_TO_CREATE = "UNAUTHORIZED_TO_CREATE",
  UNAUTHORIZED_TO_DELETE = "UNAUTHORIZED_TO_DELETE",
  UNAUTHORIZED_TO_READ = "UNAUTHORIZED_TO_READ",
  UNAUTHORIZED_TO_READ_UPDATE = "UNAUTHORIZED_TO_READ_UPDATE",
  UNAUTHORIZED_TO_UPDATE = "UNAUTHORIZED_TO_UPDATE",
  UNSUPPORTED_BROKERAGE_ACCOUNT = "UNSUPPORTED_BROKERAGE_ACCOUNT",
  UNSUPPORTED_FILE_TYPE = "UNSUPPORTED_FILE_TYPE",
  UPDATE_EMAIL_FORBIDDEN = "UPDATE_EMAIL_FORBIDDEN",
  UPDATE_EMAIL_REQUEST_NOT_EXISTS = "UPDATE_EMAIL_REQUEST_NOT_EXISTS",
  UPDATE_EMAIL_TOKEN_EXPIRED = "UPDATE_EMAIL_TOKEN_EXPIRED",
  USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
  USER_DOES_NOT_EXIST = "USER_DOES_NOT_EXIST",
  USER_DOES_NOT_HAVE_PHONE_NUMBER = "USER_DOES_NOT_HAVE_PHONE_NUMBER",
  USER_IS_CITIZEN_OR_TAX_RESIDENT = "USER_IS_CITIZEN_OR_TAX_RESIDENT",
  USER_NOT_THIRD_PARTY_TAX_ADVISOR = "USER_NOT_THIRD_PARTY_TAX_ADVISOR",
  USER_ORDER_NOT_FOUND = "USER_ORDER_NOT_FOUND",
  USER_ROLE_FORBIDDEN = "USER_ROLE_FORBIDDEN",
  USER_SESSION_INVALID_FORBIDDEN = "USER_SESSION_INVALID_FORBIDDEN",
  VISITS_REDIRECT_BAD_REQUEST = "VISITS_REDIRECT_BAD_REQUEST",
  VISIT_NOT_FOUND = "VISIT_NOT_FOUND",
  WALLET_FAILED_TO_GET_BALANCE = "WALLET_FAILED_TO_GET_BALANCE",
  WALLET_NAME_ALREADY_EXISTS = "WALLET_NAME_ALREADY_EXISTS",
  WALLET_OWNER_NOT_FOUND = "WALLET_OWNER_NOT_FOUND",
  WEB_FORM_NOT_FOUND = "WEB_FORM_NOT_FOUND",
  WEPA_3PTA_MUST_HAVE_A_TAX_ADVISOR = "WEPA_3PTA_MUST_HAVE_A_TAX_ADVISOR",
  WEPA_ANNUAL_BOOKING_INVALID_TOKEN = "WEPA_ANNUAL_BOOKING_INVALID_TOKEN",
  WEPA_ANNUAL_BOOKING_NOT_CUSTOMER_FINAL = "WEPA_ANNUAL_BOOKING_NOT_CUSTOMER_FINAL",
  WEPA_ANNUAL_BOOKING_NOT_FOUND = "WEPA_ANNUAL_BOOKING_NOT_FOUND",
  WEPA_ANNUAL_BOOKING_NOT_IN_THE_PAST = "WEPA_ANNUAL_BOOKING_NOT_IN_THE_PAST",
  WEPA_ANNUAL_BOOKING_NOT_PRELIMINARY = "WEPA_ANNUAL_BOOKING_NOT_PRELIMINARY",
  WEPA_BAD_GATEWAY = "WEPA_BAD_GATEWAY",
  WEPA_BOOKING_FILE_IS_NOT_STREAM = "WEPA_BOOKING_FILE_IS_NOT_STREAM",
  WEPA_BOOKING_IN_PROGRESS = "WEPA_BOOKING_IN_PROGRESS",
  WEPA_COMPANY_NOT_FOUND = "WEPA_COMPANY_NOT_FOUND",
  WEPA_CUSTOMER_MUST_NOT_HAVE_A_TAX_ADVISOR = "WEPA_CUSTOMER_MUST_NOT_HAVE_A_TAX_ADVISOR",
  WEPA_CUSTOMER_NOT_INVOCEABLE = "WEPA_CUSTOMER_NOT_INVOCEABLE",
  WEPA_NOT_EXIST = "WEPA_NOT_EXIST",
  WEPA_SERVICE_INVOICE_DATA_NOT_FOUND = "WEPA_SERVICE_INVOICE_DATA_NOT_FOUND",
  WEPA_SERVICE_NOT_FOUND = "WEPA_SERVICE_NOT_FOUND",
  WEPA_SERVICE_TASK_NOT_FOUND = "WEPA_SERVICE_TASK_NOT_FOUND",
  WEPA_SERVICE_TEMPORARILY_UNAVAILABLE = "WEPA_SERVICE_TEMPORARILY_UNAVAILABLE",
  WEPA_TASK_REF_NOT_FOUND = "WEPA_TASK_REF_NOT_FOUND",
}

export enum EventEntityType {
  Annual = "Annual",
  BankAccount = "BankAccount",
  BrokerageAccount = "BrokerageAccount",
  Company = "Company",
  CompanyCreation = "CompanyCreation",
  Customer = "Customer",
  DiscountCode = "DiscountCode",
  FileUploadToken = "FileUploadToken",
  FiscalYear = "FiscalYear",
  Investment = "Investment",
  LTBOrderExternalValidation = "LTBOrderExternalValidation",
  Order = "Order",
  PaymentCustomer = "PaymentCustomer",
  Person = "Person",
  User = "User",
}

export enum EventName {
  AccountFundedByClient = "AccountFundedByClient",
  ActiveTaxAdvisorUnassigned = "ActiveTaxAdvisorUnassigned",
  AdminServiceArchived = "AdminServiceArchived",
  AdminServiceOfferDeclined = "AdminServiceOfferDeclined",
  AdminServiceOrderCompletedThroughUpsell = "AdminServiceOrderCompletedThroughUpsell",
  AdminServiceOrderCreatedThroughUpsell = "AdminServiceOrderCreatedThroughUpsell",
  AdminServiceOrderFinalized = "AdminServiceOrderFinalized",
  AdminServiceOrdered = "AdminServiceOrdered",
  AdminServicePTAMigrationToRideSteuerberatungOrderFinalized = "AdminServicePTAMigrationToRideSteuerberatungOrderFinalized",
  AssignedShelfCompany = "AssignedShelfCompany",
  BrokerageAccountAdded = "BrokerageAccountAdded",
  BrokerageAccountDeleted = "BrokerageAccountDeleted",
  BrokerageAccountMoved = "BrokerageAccountMoved",
  BrokerageAccountOperationFailed = "BrokerageAccountOperationFailed",
  BrokerageAccountStatusUpdated = "BrokerageAccountStatusUpdated",
  BrokerageAccountUpdated = "BrokerageAccountUpdated",
  BrokerageAccountValidityUpdatedToInvalid = "BrokerageAccountValidityUpdatedToInvalid",
  BrokerageSurveySubmitted = "BrokerageSurveySubmitted",
  CompanyCreated = "CompanyCreated",
  CompanyCreationUpdated = "CompanyCreationUpdated",
  CompanyFoundingArchived = "CompanyFoundingArchived",
  CompanyInfoSyncToCRMTriggered = "CompanyInfoSyncToCRMTriggered",
  CompanyMarkedForMigration = "CompanyMarkedForMigration",
  CompanyUpdated = "CompanyUpdated",
  ContactPersonUpdated = "ContactPersonUpdated",
  CreditNoteCreated = "CreditNoteCreated",
  CreditNoteIssued = "CreditNoteIssued",
  CustomerUpdated = "CustomerUpdated",
  DeleteBankAccount = "DeleteBankAccount",
  DiscountCodeUpdated = "DiscountCodeUpdated",
  DocumentDownloaded = "DocumentDownloaded",
  DocumentSigned = "DocumentSigned",
  EmailLinkConfirmed = "EmailLinkConfirmed",
  ExternalStorageUserCreated = "ExternalStorageUserCreated",
  ExternalStorageUserDeleted = "ExternalStorageUserDeleted",
  FileUploadFinished = "FileUploadFinished",
  FileUploadStarted = "FileUploadStarted",
  FileUploadURLCreated = "FileUploadURLCreated",
  FiscalYearDataUploadCompleted = "FiscalYearDataUploadCompleted",
  FiscalYearUpdated = "FiscalYearUpdated",
  IBCredentialsEmailSent = "IBCredentialsEmailSent",
  InviteUser = "InviteUser",
  InvoiceFinalized = "InvoiceFinalized",
  LTBApplicationReviewedByOperations = "LTBApplicationReviewedByOperations",
  LTBOrderSubmittedToFXFlat = "LTBOrderSubmittedToFXFlat",
  LTBOrderValidationDeleted = "LTBOrderValidationDeleted",
  LTBSetupAccountCompletedByClient = "LTBSetupAccountCompletedByClient",
  LTBVerifyIdStepCompletedByClient = "LTBVerifyIdStepCompletedByClient",
  LTBVerifyIdentityOpened = "LTBVerifyIdentityOpened",
  LeiUpdated = "LeiUpdated",
  LoggedInAsTaxAdvisor = "LoggedInAsTaxAdvisor",
  LoggedInAsUser = "LoggedInAsUser",
  MigrateAdminServicePackageHasBeenReset = "MigrateAdminServicePackageHasBeenReset",
  MigratePTAInformationToStripe = "MigratePTAInformationToStripe",
  NetLiquidationValueChangedCurrentQuarter = "NetLiquidationValueChangedCurrentQuarter",
  NetLiquidationValueChangedPastQuarter = "NetLiquidationValueChangedPastQuarter",
  NetLiquidationValueCreatedCurrentQuarter = "NetLiquidationValueCreatedCurrentQuarter",
  NetLiquidationValueCreatedPastQuarter = "NetLiquidationValueCreatedPastQuarter",
  NomineeMarkedAccountAsFunded = "NomineeMarkedAccountAsFunded",
  OrderCreated = "OrderCreated",
  OrderFinalized = "OrderFinalized",
  OrderUpdated = "OrderUpdated",
  PrefilledGSOrderCreated = "PrefilledGSOrderCreated",
  ProcessTaskCompleted = "ProcessTaskCompleted",
  ProcessTaskOpened = "ProcessTaskOpened",
  ShelfCompanyTransferred = "ShelfCompanyTransferred",
  SubscriptionCanceled = "SubscriptionCanceled",
  SyncWepaCompanyOperationFailed = "SyncWepaCompanyOperationFailed",
  SyncWepaCompanyRequested = "SyncWepaCompanyRequested",
  TaxAdvisorActivated = "TaxAdvisorActivated",
  TaxAdvisorAssigned = "TaxAdvisorAssigned",
  TaxAdvisorChanged = "TaxAdvisorChanged",
  TaxAdvisorExternalStoragePermissionsFinished = "TaxAdvisorExternalStoragePermissionsFinished",
  TaxAdvisorExternalStoragePermissionsStarted = "TaxAdvisorExternalStoragePermissionsStarted",
  ThirdPartyTaxAdvisorCompanyUpdated = "ThirdPartyTaxAdvisorCompanyUpdated",
  ThirdPartyTaxAdvisorMigrationContractSigned = "ThirdPartyTaxAdvisorMigrationContractSigned",
  UnassignedShelfCompany = "UnassignedShelfCompany",
  UpsertBankAccount = "UpsertBankAccount",
  WepaBookingDownloaded = "WepaBookingDownloaded",
  WepaBookingFlagSet = "WepaBookingFlagSet",
  WepaDirectArchived = "WepaDirectArchived",
  WepaServiceArchived = "WepaServiceArchived",
  WepaServiceTaskCompleted = "WepaServiceTaskCompleted",
  WepaServiceTaskOpened = "WepaServiceTaskOpened",
  WepaServiceUpdated = "WepaServiceUpdated",
  WepaSubscriptionCancelled = "WepaSubscriptionCancelled",
  WepaSubscriptionCreatedInPaymentPlatform = "WepaSubscriptionCreatedInPaymentPlatform",
  WepaUsageRecordedInPaymentPlatform = "WepaUsageRecordedInPaymentPlatform",
}

export enum FeatureFlags {
  ADB_RideBroker_UpdateIBKRCredential = "ADB_RideBroker_UpdateIBKRCredential",
  ASMigrationNotificationEmail = "ASMigrationNotificationEmail",
  ActivTradesBroker = "ActivTradesBroker",
  AddCompanyDetailsCardInLowTaxBrokerOnbordingSteps = "AddCompanyDetailsCardInLowTaxBrokerOnbordingSteps",
  AddCompanyNameToPackageSelectorFlow = "AddCompanyNameToPackageSelectorFlow",
  AddExistingCompanyNewForm = "AddExistingCompanyNewForm",
  AddLegalFormToStripeCustomerName = "AddLegalFormToStripeCustomerName",
  AddMiddleNameToLTBForm = "AddMiddleNameToLTBForm",
  AdminServiceInAppOnboarding = "AdminServiceInAppOnboarding",
  AdminServicePTAMigrationToRideSteuerberatung = "AdminServicePTAMigrationToRideSteuerberatung",
  AdminServiceUpsellOffering = "AdminServiceUpsellOffering",
  AdmiralMarketsBroker = "AdmiralMarketsBroker",
  ApproveLTBDocumentByOps = "ApproveLTBDocumentByOps",
  BankAccountDetails = "BankAccountDetails",
  BankAccountsList = "BankAccountsList",
  BankingPageInCompanyNavigation = "BankingPageInCompanyNavigation",
  BreidenMandatsvereinbarungNewFile = "BreidenMandatsvereinbarungNewFile",
  BrokerageAccountValidityUpdatedToInvalid = "BrokerageAccountValidityUpdatedToInvalid",
  ChoosePaymentMethodGSTask = "ChoosePaymentMethodGSTask",
  ClientInvestments = "ClientInvestments",
  CompanyByIdAPI = "CompanyByIdAPI",
  CompanyCategoryQuestionShortOrderForm = "CompanyCategoryQuestionShortOrderForm",
  CompanyMainActivitiesForAddExistingCompanyForm = "CompanyMainActivitiesForAddExistingCompanyForm",
  CompanySearchInputShortOrderForm = "CompanySearchInputShortOrderForm",
  ConversionSKR04ToSKR03 = "ConversionSKR04ToSKR03",
  CorporateShareholderCompaniesForLowTaxBrokerOrders = "CorporateShareholderCompaniesForLowTaxBrokerOrders",
  CreateNewAccountShortOrderForm = "CreateNewAccountShortOrderForm",
  DeleteDraftGSOrderForCustomer = "DeleteDraftGSOrderForCustomer",
  DeprecatedAssetManager = "DeprecatedAssetManager",
  DisableUnnecessaryTransactionalEmails = "DisableUnnecessaryTransactionalEmails",
  DisabledToggle = "DisabledToggle",
  DiscordCommunityButton = "DiscordCommunityButton",
  EnableHomeTab = "EnableHomeTab",
  EnabledToggle = "EnabledToggle",
  FiscalYearDataUploadConfirmation = "FiscalYearDataUploadConfirmation",
  FoundationServiceStepFilter = "FoundationServiceStepFilter",
  FoundationServiceStepStatusFilter = "FoundationServiceStepStatusFilter",
  FxFlatXML = "FxFlatXML",
  FyrstOnBoardingLink = "FyrstOnBoardingLink",
  GBEBroker = "GBEBroker",
  GRO1070AddLEINumberSectionForLEIAdmin = "GRO1070AddLEINumberSectionForLEIAdmin",
  GRO1377LeiBrokerOrderUpsell = "GRO1377LeiBrokerOrderUpsell",
  GRO777MigrateAdminServicePackage = "GRO777MigrateAdminServicePackage",
  GRO876FillSEPASectionInAdminServiceContract = "GRO876FillSEPASectionInAdminServiceContract",
  GRO876FillSEPASectionInAdminServiceContractUnsigned = "GRO876FillSEPASectionInAdminServiceContractUnsigned",
  GRO957UpdateDealForSteueragentenAfterSignMigrateAdminServicePackageContract = "GRO957UpdateDealForSteueragentenAfterSignMigrateAdminServicePackageContract",
  GRO982Migrate3PTAToLowtaxSteuerberatungsGmbH = "GRO982Migrate3PTAToLowtaxSteuerberatungsGmbH",
  GRO983UpsertDealWhenSigning3PTAMigration = "GRO983UpsertDealWhenSigning3PTAMigration",
  GROOfferFinomBankAccount = "GROOfferFinomBankAccount",
  GenerateOrderForEmailButtonOnGSPage = "GenerateOrderForEmailButtonOnGSPage",
  HidePaymentMethodSelectionForSteueragentenClients = "HidePaymentMethodSelectionForSteueragentenClients",
  ICMarketsBroker = "ICMarketsBroker",
  IDDocumentAdditionalInformationForClients = "IDDocumentAdditionalInformationForClients",
  IDDocumentAdditionalInformationForOps = "IDDocumentAdditionalInformationForOps",
  JFDBroker = "JFDBroker",
  LTB630MigrateAdminServicePackageShowSelectedPackage = "LTB630MigrateAdminServicePackageShowSelectedPackage",
  LTB631MigrateAdminServicePackageResetPackageChoice = "LTB631MigrateAdminServicePackageResetPackageChoice",
  LTB763SyncPaymentMethodWithGSTask = "LTB763SyncPaymentMethodWithGSTask",
  LTB768CreateBrokerageAccountByUserInput = "LTB768CreateBrokerageAccountByUserInput",
  LTB768ShowIbkrAccessInformationForLTBInLowTaxBrokerDashboard = "LTB768ShowIbkrAccessInformationForLTBInLowTaxBrokerDashboard",
  LTBContinueOrderBanner = "LTBContinueOrderBanner",
  LTBCustomerOverviewCompletionConfetti = "LTBCustomerOverviewCompletionConfetti",
  LTBDashboard = "LTBDashboard",
  LTBDashboardPayIn = "LTBDashboardPayIn",
  LTBDashboardPayOut = "LTBDashboardPayOut",
  LTBHoldingInADB = "LTBHoldingInADB",
  LTBMarkAccountFundedADB = "LTBMarkAccountFundedADB",
  LTBOrderConfirmationEmail = "LTBOrderConfirmationEmail",
  LTBOverviewStatuses = "LTBOverviewStatuses",
  LTBPayOutConfirmationEmail = "LTBPayOutConfirmationEmail",
  LTBSendPayoutJsonToFxFlat = "LTBSendPayoutJsonToFxFlat",
  LTBShareholderPrefilling = "LTBShareholderPrefilling",
  LTBTradingPermissionConstraint = "LTBTradingPermissionConstraint",
  LowTaxBroker = "LowTaxBroker",
  LowTaxBrokerCustomerOverview = "LowTaxBrokerCustomerOverview",
  LowTaxBrokerFromDepositAccountSetup = "LowTaxBrokerFromDepositAccountSetup",
  LowTaxBrokerReviewDocument = "LowTaxBrokerReviewDocument",
  LowTaxBrokerSendVerifyIdEmailToShareholders = "LowTaxBrokerSendVerifyIdEmailToShareholders",
  LowTaxBrokerValidateCompanyLeiNumber = "LowTaxBrokerValidateCompanyLeiNumber",
  MoveASDealToOrderedWhenCompanySetupIsCompleted = "MoveASDealToOrderedWhenCompanySetupIsCompleted",
  NewAddGmbHModal = "NewAddGmbHModal",
  NewBroker = "NewBroker",
  NomineeUpdateLTBOrderStatus = "NomineeUpdateLTBOrderStatus",
  OpsUpdateLTBIbanInLTBOrderDetails = "OpsUpdateLTBIbanInLTBOrderDetails",
  PTAWepaDocuments = "PTAWepaDocuments",
  PendingActions = "PendingActions",
  PepperstoneBroker = "PepperstoneBroker",
  PrefillAddExistingCompanyWithNorthData = "PrefillAddExistingCompanyWithNorthData",
  PrefillLTBOrderFromUnifiedOrderForm = "PrefillLTBOrderFromUnifiedOrderForm",
  PushCompanyUpdatedEvent = "PushCompanyUpdatedEvent",
  RE52AddRealEstateSection = "RE52AddRealEstateSection",
  RE52RealEstateAppAccess = "RE52RealEstateAppAccess",
  RemoveChoosePackageFromASOrder = "RemoveChoosePackageFromASOrder",
  RenameVvSteuerberatungToRideSteuerberatung = "RenameVvSteuerberatungToRideSteuerberatung",
  ReplaceCompanyListByCompanyDropdown = "ReplaceCompanyListByCompanyDropdown",
  ReportTaxIdToFxFlat = "ReportTaxIdToFxFlat",
  RideBrokerOnlySignup = "RideBrokerOnlySignup",
  RideBrokerWithoutHouseNumber = "RideBrokerWithoutHouseNumber",
  RoboForexBroker = "RoboForexBroker",
  ShowAdditionalFiscalYearSectionInUnifiedOrderForm = "ShowAdditionalFiscalYearSectionInUnifiedOrderForm",
  ShowGSPendingOrderBannerInCompanyNavigation = "ShowGSPendingOrderBannerInCompanyNavigation",
  ShowRideBrokerUnderMaintenance = "ShowRideBrokerUnderMaintenance",
  SlowQueryTestingForTaxAdvisorCompanies = "SlowQueryTestingForTaxAdvisorCompanies",
  SunsetOldOrderFlows = "SunsetOldOrderFlows",
  SwanBankSelection = "SwanBankSelection",
  SyncASDealFromADB = "SyncASDealFromADB",
  SyncWepaCompany = "SyncWepaCompany",
  SynchroniseCompaniesToStripe = "SynchroniseCompaniesToStripe",
  SynchronizePTAInformationToStripeEndpoint = "SynchronizePTAInformationToStripeEndpoint",
  SynchronizeTaxAdvisorInformationToStripe = "SynchronizeTaxAdvisorInformationToStripe",
  TaxAdvisorCompanyMinimal = "TaxAdvisorCompanyMinimal",
  TaxReporting = "TaxReporting",
  ThunderForexBroker = "ThunderForexBroker",
  ToggledUser = "ToggledUser",
  TradesPerYearAsDropdownSelect = "TradesPerYearAsDropdownSelect",
  TradingTransactionHistory = "TradingTransactionHistory",
  TransactionalEmails = "TransactionalEmails",
  TransferCompanyOwnership = "TransferCompanyOwnership",
  TwoFactorAuthenticationSetup = "TwoFactorAuthenticationSetup",
  UpdateCheckboxesSectionForSteueragenten = "UpdateCheckboxesSectionForSteueragenten",
  UpdateDealOnSendToBrokerClick = "UpdateDealOnSendToBrokerClick",
  UpdateLTBDealOnAddExistingCompanyFinalizer = "UpdateLTBDealOnAddExistingCompanyFinalizer",
  UploadProofOfSharedCapital = "UploadProofOfSharedCapital",
  UseASInvoiceDataServiceStartingDateForFiscalYearCreation = "UseASInvoiceDataServiceStartingDateForFiscalYearCreation",
  UseBrokerAtRideCapitalEmail = "UseBrokerAtRideCapitalEmail",
  WP6TaxAdvisorClientSearch = "WP6TaxAdvisorClientSearch",
  WealthNavigation = "WealthNavigation",
  WepaAnnualBookings = "WepaAnnualBookings",
  WepaBookingAnnualFlagTransactionalEmail = "WepaBookingAnnualFlagTransactionalEmail",
  WepaBookingsDownloadForClient = "WepaBookingsDownloadForClient",
  WepaBrokerConnected = "WepaBrokerConnected",
  WepaDirect = "WepaDirect",
  WepaServiceOrdered = "WepaServiceOrdered",
  WepaServiceSwitchToAdminService = "WepaServiceSwitchToAdminService",
  YearsTradingAsDropdownSelect = "YearsTradingAsDropdownSelect",
  doNotSendRemindEmailWhenGmbhOrderIsCreatedByNominee = "doNotSendRemindEmailWhenGmbhOrderIsCreatedByNominee",
  exposeEndpointToSetOrderedAtEqualToCreatedAtForASCompanyCreations = "exposeEndpointToSetOrderedAtEqualToCreatedAtForASCompanyCreations",
  fiscalYearConfirmationReminderEmail = "fiscalYearConfirmationReminderEmail",
  hasLanguageSwitcherInAllFooters = "hasLanguageSwitcherInAllFooters",
  redirectToOrderFlowOnFirstLogin = "redirectToOrderFlowOnFirstLogin",
  removeIntendedInitialInvestmentStep = "removeIntendedInitialInvestmentStep",
  renderShortOrderFormOrderSummaryV2 = "renderShortOrderFormOrderSummaryV2",
  resumeLinkForGSFlowV2 = "resumeLinkForGSFlowV2",
  showAngelInvestFundCampaign = "showAngelInvestFundCampaign",
  showBusinessOperationSourceOfWealth = "showBusinessOperationSourceOfWealth",
  showPartnerTypeAndVotesForTAAnd3PTA = "showPartnerTypeAndVotesForTAAnd3PTA",
  showPasswordResetForUsersLoggedInViaFinApi = "showPasswordResetForUsersLoggedInViaFinApi",
  showReferralCampaignBanner = "showReferralCampaignBanner",
  showShelfCompanyOrders = "showShelfCompanyOrders",
  syncDealsToHubspotOnCompletion = "syncDealsToHubspotOnCompletion",
  thirdPartyTaxAdvisorStripeSubscription5Record = "thirdPartyTaxAdvisorStripeSubscription5Record",
  thirdPartyTaxAdvisorStripeSubscriptionDryRun = "thirdPartyTaxAdvisorStripeSubscriptionDryRun",
  useGuestHeaderAsDesignSystem = "useGuestHeaderAsDesignSystem",
}

export enum FlowTypeEnum {
  ADMIN_SERVICE = "ADMIN_SERVICE",
  ADMIN_SERVICE_ONBOARDING = "ADMIN_SERVICE_ONBOARDING",
  FOUNDER_HOLDING = "FOUNDER_HOLDING",
  GMBH = "GMBH",
  HOLDING_GMBH = "HOLDING_GMBH",
  INVESTOR_HOLDING = "INVESTOR_HOLDING",
  LOW_TAX_BROKER = "LOW_TAX_BROKER",
  REAL_ESTATE_GMBH = "REAL_ESTATE_GMBH",
  SHELF_GMBH = "SHELF_GMBH",
  TRADER_HOLDING = "TRADER_HOLDING",
  WEPA_SERVICE = "WEPA_SERVICE",
}

export enum FormerClientAssignmentType {
  AdminService = "AdminService",
  WepaStandalone = "WepaStandalone",
}

export enum GroupType {
  AdminService = "AdminService",
  Company = "Company",
  CompanyCreation = "CompanyCreation",
  Document = "Document",
  Dream = "Dream",
  MembershipShare = "MembershipShare",
  RealEstate = "RealEstate",
  SPV = "SPV",
}

export enum InvestmentState {
  Private = "Private",
  RideClubCompleted = "RideClubCompleted",
  RideClubInProcess = "RideClubInProcess",
}

export enum InvoiceSource {
  Ride = "Ride",
  TaxAdvisor = "TaxAdvisor",
}

export enum KycEntryTypeEnum {
  COMPANY = "COMPANY",
  PERSON = "PERSON",
}

export enum KycStatusEnum {
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
  PENDING = "PENDING",
  STARTED = "STARTED",
}

export enum LegalForm {
  AG = "AG",
  AG_Co_KG = "AG_Co_KG",
  EG = "EG",
  GbR = "GbR",
  GmbH = "GmbH",
  GmbH_Co_KG = "GmbH_Co_KG",
  GmbH_i_G = "GmbH_i_G",
  KG = "KG",
  KGaA = "KGaA",
  MbH = "MbH",
  None_Einzelunternehmen = "None_Einzelunternehmen",
  OHG = "OHG",
  PartG = "PartG",
  PartGmbB = "PartGmbB",
  Stiftung = "Stiftung",
  Stiftung_Co_KG = "Stiftung_Co_KG",
  UG = "UG",
  UG_Co_KG = "UG_Co_KG",
  eK = "eK",
  eV = "eV",
}

export enum LegalPersonType {
  Company = "Company",
  Person = "Person",
}

export enum NetLiquidationValueTag {
  Current = "Current",
  Last = "Last",
}

export enum NetLiquidationValueType {
  Brokerage = "Brokerage",
}

export enum NotaryDocumentType {
  InstructionLetter = "InstructionLetter",
  MoneyLaunderingQuestionnaire = "MoneyLaunderingQuestionnaire",
}

export enum NotaryType {
  CompanyHolding = "CompanyHolding",
  MultipleShareholders = "MultipleShareholders",
  SingleShareholder = "SingleShareholder",
}

export enum OrderCompanyStatusEnum {
  Assigned = "Assigned",
  Booked = "Booked",
  Canceled = "Canceled",
  Completed = "Completed",
  InProgress = "InProgress",
  Transferred = "Transferred",
}

export enum OrderEntityType {
  Company = "Company",
}

export enum OrderOwnerType {
  Company = "Company",
  Guest = "Guest",
  Person = "Person",
}

export enum OrderProductTypeEnum {
  AddExistingCompany = "AddExistingCompany",
  AdminService = "AdminService",
  AdminServicePTAMigrationToRideSteuerberatung = "AdminServicePTAMigrationToRideSteuerberatung",
  BrokerageAccount = "BrokerageAccount",
  CompanyFoundation = "CompanyFoundation",
  LEI = "LEI",
  MigrateAdminServicePackage = "MigrateAdminServicePackage",
  ShelfCompany = "ShelfCompany",
  UnifiedShortOrderForm = "UnifiedShortOrderForm",
  UserAccountViaCompanyFoundation = "UserAccountViaCompanyFoundation",
  WepaDirect = "WepaDirect",
  WepaStandaloneMigration = "WepaStandaloneMigration",
}

export enum PackageTypeEnum {
  INVESTOR = "INVESTOR",
  PAPER_ASSETS = "PAPER_ASSETS",
  REAL_ESTATE = "REAL_ESTATE",
  TRADER = "TRADER",
}

export enum PaymentPlatformAccount {
  RIDE = "RIDE",
  vvSteuerberatung = "vvSteuerberatung",
}

export enum PostOrderStatusEnum {
  ACCOUNT_OPENING = "ACCOUNT_OPENING",
  ACCOUNT_PREPARATION = "ACCOUNT_PREPARATION",
  CAPITAL_PAYMENT = "CAPITAL_PAYMENT",
  COMPANY_NOTARIZATION = "COMPANY_NOTARIZATION",
  DEPOT_OPENING = "DEPOT_OPENING",
  EMAIL_CONFIRMATION = "EMAIL_CONFIRMATION",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  SHAREHOLDER_AUTHORIZATION = "SHAREHOLDER_AUTHORIZATION",
}

export enum PublishType {
  Completed = "Completed",
  Disclosed = "Disclosed",
  Draft = "Draft",
  Published = "Published",
  Undisclosed = "Undisclosed",
}

export enum RealEstateAssetType {
  IncomeProducing = "IncomeProducing",
  LandPlot = "LandPlot",
  Redevelopment = "Redevelopment",
}

export enum ResumeLinkType {
  MAGIC = "MAGIC",
  RESUME = "RESUME",
}

export enum RolesInGroup {
  CompanyCreator = "CompanyCreator",
  Director = "Director",
  Orderer = "Orderer",
  Owner = "Owner",
  RegisteredManager = "RegisteredManager",
  Shareholder = "Shareholder",
  SilentPartner = "SilentPartner",
}

export enum SalutationType {
  Mr = "Mr",
  Ms = "Ms",
}

export enum SearchTypeEnum {
  Company = "Company",
  Person = "Person",
}

export enum StepStatesEnum {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_REQUIRED = "NOT_REQUIRED",
  TO_BE_DONE = "TO_BE_DONE",
}

export enum StorageNodeType {
  FILE = "FILE",
  FOLDER = "FOLDER",
}

export enum StorageNodesEntityType {
  Company = "Company",
  Investment = "Investment",
  Person = "Person",
}

export enum TaskStatesEnum {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_POSSIBLE_YET = "NOT_POSSIBLE_YET",
  NOT_REQUIRED = "NOT_REQUIRED",
  TO_BE_DONE = "TO_BE_DONE",
}

export enum TaskType {
  BANK_ACCOUNT_OPEN_FINOM = "BANK_ACCOUNT_OPEN_FINOM",
  BANK_ACCOUNT_OPEN_FYRST = "BANK_ACCOUNT_OPEN_FYRST",
  BANK_ACCOUNT_OPEN_OWN = "BANK_ACCOUNT_OPEN_OWN",
  BANK_ACCOUNT_OPEN_PENTA = "BANK_ACCOUNT_OPEN_PENTA",
  BANK_ACCOUNT_OPEN_SWAN = "BANK_ACCOUNT_OPEN_SWAN",
  BANK_ACCOUNT_PROVIDER = "BANK_ACCOUNT_PROVIDER",
  CHOOSE_FISCAL_YEAR = "CHOOSE_FISCAL_YEAR",
  CHOOSE_PAYMENT_METHOD = "CHOOSE_PAYMENT_METHOD",
  DOCU_SIGN = "DOCU_SIGN",
  GENERIC = "GENERIC",
  IBAN = "IBAN",
  IBAN_VIEW = "IBAN_VIEW",
  NOTARY_APPOINTMENT = "NOTARY_APPOINTMENT",
  TEXT_AREA = "TEXT_AREA",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
}

export enum TaxAdvisorStatusEnum {
  ACTIVE = "ACTIVE",
  ASSIGNED = "ASSIGNED",
}

export enum TaxServiceTypeEnum {
  AdminService = "AdminService",
  WepaService = "WepaService",
  WepaSubContracting = "WepaSubContracting",
}

export enum UserRole {
  Admin = "Admin",
  AssetManager = "AssetManager",
  Client = "Client",
  Nominee = "Nominee",
  Notary = "Notary",
  TaxAdvisor = "TaxAdvisor",
  ThirdPartyTaxAdvisor = "ThirdPartyTaxAdvisor",
}

export enum WalletType {
  BTC = "BTC",
  ETH = "ETH",
}

export enum WepaBookingStatusFlag {
  customer_final = "customer_final",
  in_progress = "in_progress",
  partner_final = "partner_final",
  preliminary = "preliminary",
}

export enum WepaBrokerAccessValidEnum {
  Deleted = "Deleted",
  Expired = "Expired",
  Invalid = "Invalid",
  New = "New",
  NotImplemented = "NotImplemented",
  Processing = "Processing",
  ReportInvalid = "ReportInvalid",
  Stale = "Stale",
  Unmatched = "Unmatched",
  Valid = "Valid",
  deleted = "deleted",
  expired = "expired",
  invalid = "invalid",
  new = "new",
  notImplemented = "notImplemented",
  processing = "processing",
  reportInvalid = "reportInvalid",
  stale = "stale",
  unmatched = "unmatched",
  valid = "valid",
}

export enum WepaServiceStep {
  COMPANY_DETAILS = "COMPANY_DETAILS",
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  DIRECTOR = "DIRECTOR",
  ORDERED = "ORDERED",
}

export interface CompanyAddress {
  careOf?: string | null;
  street: string;
  streetLine2?: string | null;
  city: string;
  postalCode: string;
  country?: string | null;
}

export interface CreateCompanyLeadAddressData {
  country: string;
  city: string;
  postalCode: string;
  streetLine1: string;
  streetLine2?: string | null;
}

export interface CreateCompanyLeadCompanyData {
  companyName: string;
  legalForm?: LegalForm | null;
  registration?: string | null;
  registerCourt?: string | null;
}

export interface FiscalYearInput {
  year: number;
  dataUploadCompleted: boolean;
}

export interface InputProcessTaskOptions {
  type?: string | null;
  data?: any | null;
}

export interface NotaryLinkItem {
  notaryDocumentType: NotaryDocumentType;
  link: string;
}

export interface OrdererCompanyData {
  name: string;
  legalForm: LegalForm;
  registration: string;
  registerCourt: string;
  taxNumber?: string | null;
  economicId?: string | null;
}

export interface PersonData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface PersonRole {
  id: string;
  isDirector: boolean;
}

export interface PersonShare {
  id: string;
  share: number;
}

export interface ShareholderCompany {
  id?: string | null;
  name: string;
  legalForm: LegalForm;
  registration: string;
  registerCourt: string;
  street: string;
  postalCode: string;
  city: string;
}

export interface ShareholderDetails {
  id: string;
  dateOfBirth: string;
  citizenship?: string | null;
  street: string;
  city: string;
  postalCode: string;
  country?: string | null;
  personalTaxId?: string | null;
  taxNumber?: string | null;
  birthName?: string | null;
  placeOfBirth?: string | null;
}

export interface ShareholderPerson {
  id?: string | null;
  email?: string | null;
  salutation: SalutationType;
  title?: string | null;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
