import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ExpertAvatars } from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/BookACallCard/ExpertAvatars/ExpertAvatars";
import TrustpilotInfo from "client/components/ShortOrderFormEngine/ShortOrderFormLayout/BookACallCard/TrustpilotInfo/TrustpilotInfo";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";
import { PhoneFilledIcon } from "sharedComponents/icons/DesignSystemIcons/PhoneFilledIcon";
import NavigationService from "lib/services/NavigationService/NavigationService";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import { FeatureFlags } from "global-query-types";

interface BookACallCardProps extends WithTranslation {}

const moduleName = "book-a-call-card";

const BookACallCard = ({ t }: BookACallCardProps) => {
  const onlyBroker = FeatureFlagService.instance.isEnabled(FeatureFlags.RideBrokerOnlySignup);

  const onClickButton = () => {
    NavigationService.instance?.openLink(
      onlyBroker
        ? t("generic:ride-broker-only-signup-order-form.book-a-call-url")
        : t("generic:book-a-call-url")
    );
  };

  return (
    <div data-testid="book-a-call-card" className={moduleName}>
      {!onlyBroker && <ExpertAvatars />}
      <Typography
        category="Paragraph"
        size={100}
        weight="Heavy"
        data-testid="book-a-call-card-title"
        className={`${moduleName}__title`}>
        {onlyBroker
          ? t("generic:ride-broker-only-signup-order-form.book-a-call-card.title")
          : t("generic:book-a-call-card.title")}
      </Typography>
      <Typography
        category="Paragraph"
        size={200}
        weight="Light"
        data-testid="book-a-call-card-description"
        className={`${moduleName}__description`}>
        {onlyBroker
          ? t("generic:ride-broker-only-signup-order-form.book-a-call-card.description")
          : t("generic:book-a-call-card.description")}
      </Typography>
      <RideButton
        data-testid="book-a-call-card-button"
        className={`${moduleName}__button`}
        variant={RideButtonVariant.PRIMARY}
        size={RideButtonSize.SMALL}
        leftIcon={<PhoneFilledIcon size={16} />}
        label={t("generic:book-a-call-card.button-text")}
        onClick={onClickButton}
      />
      <TrustpilotInfo />
    </div>
  );
};

export default withTranslationReady(["generic"])(BookACallCard);
